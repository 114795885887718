import React from 'react'

const InstaIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_45_2443)">
    <path d="M8.00331 4.95732C5.73189 4.95732 3.89975 6.76084 3.89975 8.99677C3.89975 11.2327 5.73189 13.0362 8.00331 13.0362C10.2747 13.0362 12.1069 11.2327 12.1069 8.99677C12.1069 6.76084 10.2747 4.95732 8.00331 4.95732ZM8.00331 11.6229C6.53546 11.6229 5.33546 10.4452 5.33546 8.99677C5.33546 7.54834 6.53189 6.3706 8.00331 6.3706C9.47474 6.3706 10.6712 7.54834 10.6712 8.99677C10.6712 10.4452 9.47117 11.6229 8.00331 11.6229V11.6229ZM13.2319 4.79209C13.2319 5.31592 12.8033 5.73428 12.2747 5.73428C11.7426 5.73428 11.3176 5.3124 11.3176 4.79209C11.3176 4.27178 11.7462 3.8499 12.2747 3.8499C12.8033 3.8499 13.2319 4.27178 13.2319 4.79209ZM15.9497 5.74834C15.889 4.48623 15.5962 3.36826 14.6569 2.44717C13.7212 1.52607 12.5855 1.23779 11.3033 1.17451C9.98188 1.10068 6.02117 1.10068 4.69975 1.17451C3.42117 1.23428 2.28546 1.52256 1.34618 2.44365C0.406892 3.36475 0.117606 4.48271 0.0533203 5.74482C-0.0216797 7.0456 -0.0216797 10.9444 0.0533203 12.2452C0.114035 13.5073 0.406892 14.6253 1.34618 15.5464C2.28546 16.4675 3.4176 16.7558 4.69975 16.819C6.02117 16.8929 9.98188 16.8929 11.3033 16.819C12.5855 16.7593 13.7212 16.471 14.6569 15.5464C15.5926 14.6253 15.8855 13.5073 15.9497 12.2452C16.0247 10.9444 16.0247 7.04912 15.9497 5.74834V5.74834ZM14.2426 13.6409C13.964 14.33 13.4247 14.8608 12.7212 15.1386C11.6676 15.5499 9.1676 15.455 8.00331 15.455C6.83903 15.455 4.33546 15.5464 3.28546 15.1386C2.58546 14.8643 2.04618 14.3335 1.76403 13.6409C1.34618 12.6038 1.4426 10.1429 1.4426 8.99677C1.4426 7.85068 1.34975 5.38623 1.76403 4.35264C2.0426 3.66357 2.58189 3.13271 3.28546 2.85498C4.33903 2.44365 6.83903 2.53857 8.00331 2.53857C9.1676 2.53857 11.6712 2.44717 12.7212 2.85498C13.4212 3.1292 13.9605 3.66006 14.2426 4.35264C14.6605 5.38974 14.564 7.85068 14.564 8.99677C14.564 10.1429 14.6605 12.6073 14.2426 13.6409Z" fill="#010205"/>
    </g>
    <defs>
    <clipPath id="clip0_45_2443">
    <rect width="16" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default InstaIcon
