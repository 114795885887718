import React,  { useState } from 'react'
import { useTheme } from '../ThemeProvider';
import BannerLight from "../Assests/Images/banner_img.png";
import BannerDark from "../Assests/Images/bg-blue-top.svg";
import ButtonTab from './Components/ButtonTab';
import PortfolioGrid from './Components/PortfolioGrid';
import Dropdown from "../Assests/Images/dropdown.svg";



const Portfolio = () => {
    const { theme } = useTheme();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('All Work [20]'); // Set the default value

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (value:any) => {
    setSelectedValue(value);
    setIsDropdownOpen(false);
  };
   
  return (
    <div className={`flex flex-col pt-[6rem] bg-no-repeat bg-cover ${theme === 'light' ? ' bg-white ' : 'bg-black '}`}
    style={{
        backgroundImage: theme === "light" ? `url(${BannerLight})` : `url(${BannerDark})`,
      }}>
        <div className='px-5 md:px-14 py-10'>
        <h1 className={`text-3xl md:text-4xl lg:text-5xl leading-130 font-medium text-center ${theme === "light" ? "text-black" : "text-white"} `}>
      Real-world examples of Unveiling Gaskon Consulting's Impact on Fulfilling Marketing Objectives
      </h1>

      <div className="flex flex-col justify-center">

          <div className="hidden md:flex flex-col md:flex-row justify-center mt-8 gap-4 px-10">
            
         <ButtonTab title={"All Work [20]"} bgColor={"bg-[#C6F1FF] text-black"} />
         <ButtonTab title={"Strategic Planning [10]"} bgColor={'bg-[#C6F1FF] text-black'} />
         <ButtonTab title={"Financial Advisory [5]"} bgColor={'bg-[#C6F1FF] text-black'} />
         <ButtonTab title={"Digital Marketing [5]"} bgColor={'bg-[#C6F1FF] text-black'} />
         <ButtonTab title={"SEO [6]"} bgColor={'bg-[#C6F1FF] text-black'} />


          </div>
          

          <div className="relative block md:hidden">
      <button
        onClick={handleDropdownToggle}
        type="button"
        className='h-12 w-full rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] mt-[30px] p-[1px] cursor-pointer hover:opacity-70'
      >
        <div className={`flex h-full w-full rounded-full items-center justify-between px-8 back ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="text-base font-bold text-[#010205]">{selectedValue}</h1>
              <span>
                <div className="ml-2">
                 <img src={Dropdown} alt="dropdown" />
                </div>
              </span>
         </div>
        
      </button>

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div className="absolute w-full mt-1 z-10 bg-white divide-y divide-gray-100 rounded-[20px] shadow  dark:bg-gray-700 ">
          <ul className="py-2 text-base font-bold text-[#010205] dark:text-gray-200">
            <li>
              <p
                onClick={() => handleOptionClick('All Work [20]')}
                className={`block px-8 py-[14px] ${
                  selectedValue === 'All Work [20]' ? 'bg-gray-100 dark:bg-gray-600 dark:text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                }`}
              >
                All Work [20]
              </p>
            </li>
            <li>
              <p
                onClick={() => handleOptionClick('Strategic  Planning [10]')}
                className={`block px-8 py-[14px] ${
                  selectedValue === 'Strategic  Planning [10]' ? 'bg-gray-100 dark:bg-gray-600 dark:text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                }`}
              >
                Strategic  Planning [10]
              </p>
            </li>
            <li>
              <p
                onClick={() => handleOptionClick('Financial Advisory [5]')}
                className={`block px-8 py-[14px] ${
                  selectedValue === 'Financial Advisory [5]' ? 'bg-gray-100 dark:bg-gray-600 dark:text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                }`}
              >
                Financial Advisory [5]
              </p>
            </li>
            <li>
              <p
                onClick={() => handleOptionClick('Digital Marketing [5]')}
                className={`block px-8 py-[14px] ${
                  selectedValue === 'Digital Marketing [5]' ? 'bg-gray-100 dark:bg-gray-600 dark:text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                }`}
              >
                Digital Marketing [5]
              </p>
            </li>
            <li>
              <p
                onClick={() => handleOptionClick('SEO [6]')}
                className={`block px-8 py-[14px] ${
                  selectedValue === 'SEO [6]' ? 'bg-gray-100 dark:bg-gray-600 dark:text-white' : 'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                }`}
              >
                SEO [6]
              </p>
            </li>
          </ul>
        </div>
      )}
           </div>
        
          <div>
          <PortfolioGrid  />;
          </div>
          </div>
        </div>
     
    </div>
  )
}

export default Portfolio
