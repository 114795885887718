import { useEffect, useState } from 'react'
import './signup-login.css'
import { useFormik } from 'formik';
import { useTheme } from '../ThemeProvider';

const LoginForm = () => {
  const { theme } = useTheme();
  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => {
      // setup rest when you get the APIs
      console.log(values);
    }
  })


  return (
    <>
      <form className='flex flex-col gap-y-4 font-semibold' onSubmit={loginForm.handleSubmit}>
        <div>
          <label htmlFor='email'>Email</label>
          <input id='email' type='email' name='email' placeholder='user@email.com' onChange={loginForm.handleChange} value={loginForm.values.email} className='input-field' />
        </div>
        <div>
          <label htmlFor='password'>Password</label>
          <input id='password' type='password' name='password' onChange={loginForm.handleChange} value={loginForm.values.password} className='input-field' />
        </div>
        <div className='mt-4 flex justify-center gap-x-5'>
          {/* <button className='submit' type='submit'>Log in as investor</button><button className='submit' type='submit'>Log in as portfolio creator</button> */}
          <div className="h-12  rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70">
            <div className={`flex h-full w-full rounded-full items-center justify-center back ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="mx-3 text-base font-semibold">Login as investor</h1>
            </div>
          </div>
          <div className="h-12  rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70">
            <div className={`flex h-full w-full rounded-full items-center justify-center back ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="mx-3 text-base font-semibold">Login as portfolio creator</h1>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const SignupForm = () => {
  const { theme } = useTheme();
  const signupForm = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    onSubmit: values => {
      // setup rest when you get the APIs
      console.log(values);
    }
  })


  return (
    <>
      <form className='flex flex-col gap-y-4 font-semibold' onSubmit={signupForm.handleSubmit}>
        <div>
          <label htmlFor='fullName'>Full name</label>
          <input id='fullName' type='text' name='fullName' placeholder='Full name' onChange={signupForm.handleChange} value={signupForm.values.fullName} className='input-field' />
        </div>
        <div>
          <label htmlFor='email'>Email</label>
          <input id='email' type='email' name='email' placeholder='user@email.com' onChange={signupForm.handleChange} value={signupForm.values.email} className='input-field' />
        </div>
        <div>
          <label htmlFor='password'>Password</label>
          <input id='password' type='password' name='password' onChange={signupForm.handleChange} value={signupForm.values.password} className='input-field' />
        </div>
        <div>
          <label htmlFor='confirmPassword'>Confirm password</label>
          <input id='confirmPassword' type='password' name='confirmPassword' onChange={signupForm.handleChange} value={signupForm.values.confirmPassword} className='input-field' />
        </div>
        <div className='mt-4 flex justify-center gap-x-5'>
          {/* <button className='submit' type='submit'>Sign up as investor</button> <button className='submit' type='submit'>Sign up as portfolio creator</button> */}
          <div className="h-12  rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70">
            <div className={`flex h-full w-full rounded-full items-center justify-center back ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="mx-3 text-base font-semibold">Sign up as investor</h1>
            </div>
          </div>
          <div className="h-12  rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70">
            <div className={`flex h-full w-full rounded-full items-center justify-center back ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="mx-3 text-base font-semibold">Sign up as portfolio creator</h1>
            </div>
          </div>
          </div>
      </form>
    </>
  )
}

function SignupLogin() {

  const [isLogIn, setIsLogin] = useState(true);

  useEffect(() => {
    const footerElem = document.getElementById("footer");
    if (footerElem) {
      footerElem.style.display = "none";
    }
  }, [])

  return (
    <>
      <section className='text-gray-800 flex h-screen main-background'>
        <div className='w-full h-full flex justify-center items-center'>
          <div>
            {/* <p className='text-7xl text-center text-white'>Gaskon</p> */}
            <div className='mt-10 w-[512px] crayon-background'>
              <div className='flex'>
                <div onClick={() => setIsLogin(true)} className={`login-signup-state hover:opacity-70 rounded-tl-xl ${isLogIn ? 'current-selected-state' : ''}`}>Log in</div>
                <div onClick={() => setIsLogin(false)} className={`login-signup-state hover:opacity-70 rounded-tr-xl ${!isLogIn ? 'current-selected-state' : ''}`} >Sign up</div>
              </div>
              {isLogIn ?
                <div className='px-5 py-8'>
                  <p className='text-center text-3xl'>Welcome back</p>
                  <div className='mt-5'><LoginForm /></div>

                </div>
                :
                <div className='px-5 py-8'>
                  <p className='text-center text-3xl'>Create an account</p>
                  <div className='mt-5'><SignupForm /></div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SignupLogin
