import React from 'react'
import { useTheme } from '../ThemeProvider';
import BannerBg from  "../Assests/Images/aboutus.png";
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import TeamMember1 from  "../Assests/Images/TeamMembers/team-1.png";
import TeamMember2 from  "../Assests/Images/TeamMembers/team-2.png";
import TeamMember3 from  "../Assests/Images/TeamMembers/team-3.png";
import TeamMember4 from  "../Assests/Images/TeamMembers/team-4.png";
import TeamMember5 from  "../Assests/Images/TeamMembers/team-5.png";
import AdMember1 from  "../Assests/Images/TeamMembers/advis-1.png";
import AdMember2 from  "../Assests/Images/TeamMembers/advis-2.png";
import AdMember3 from  "../Assests/Images/TeamMembers/advis-3.png";
import AdMember4 from  "../Assests/Images/TeamMembers/advis-4.png";
import AdMember5 from  "../Assests/Images/TeamMembers/advis-5.png";
import JoinTeam from  "../Assests/Images/TeamMembers/join_team.png";
import {useNavigate} from 'react-router-dom';


const Ourteam = () => {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/getstarted'); 
    };
  return (
    <div className= {`flex flex-col pt-[6rem] ${theme === 'light' ? 'bg-white ' : 'bg-black'}`}>
        <div className='md:px-14'>
        <div className='bg-no-repeat bg-cover md:rounded-3xl bg-[#010205d9] px-5 md:px-10 py-10 md:py-16 ' style={{
        backgroundImage:`url(${BannerBg})`
      }}>
      <h1
            className={`font-semibold text-[33px] lg:text-5xl leading-130 text-white w-full md:w-4/5 lg:w-2/3 `}
          >
            Empower Your Vision with Our Expert Team
          </h1>
          <p
            className={`font-medium text-sm lg:text-base leading-180 py-5  lg:pr-[4.5rem] text-white w-full md:w-4/5 lg:w-2/3`}
          >
            At our business consulting firm, we synergize expertise and innovation to empower organizations with strategic insights and tailored solutions. Our dynamic team of seasoned professionals is dedicated to driving client success through proactive problem-solving and sustainable growth strategies.
</p>
          <div onClick={handleClick} className="h-12 w-44 rounded-full bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70 mt-4">
            <div className="flex h-full w-full rounded-full items-center justify-center back text-white">
              <h1 className="text-sm font-medium">Get Started</h1>{" "}
              <span>
                <div className="ml-10">
                  <ArrowRightIcon className="h-5 w-5 text-white" />
                </div>
              </span>
            </div>
            </div>
    </div>
    </div>

    <div className='p-5 md:p-14 flex flex-col md:flex-row'>
    <div className="grid grid-rows-1 grid-cols-1 md:grid-rows-2 md:grid-cols-3 gap-8 w-full">
  <div className={`flex flex-col items-center lg:items-start ${theme === 'light' ? 'text-black' : ' text-white'}`}>
    <div className='flex items-center'>
  <div className="w-4 h-4 rounded-full bg-[#0076D0] mr-2 md:block hidden"> </div>
  <h4 className='text-base md:text-xl lg:text-2xl font-semibold leading-[150%] '>Meet Our Leadership Team</h4>
  </div>
  <div className='w-full xl:w-1/2 text-2xl md:text-4xl xl:text-5xl font-semibold leading-130 pt-2 md:pt-4 md:text-left text-center'>
  Meet our 
Visionaries
  </div>
  <div className="w-10 h-1 rounded-full bg-[#0076D0] mr-2 block md:hidden mt-3"> </div>
  </div>
  <div className="rounded-xl flex justify-center items-center">
    <img src={TeamMember1} alt="team members" />
  </div>
  <div className=" rounded-xl flex justify-center items-center">
    <img src={TeamMember2} alt="team members" />
  </div>

  <div className=" rounded-xl flex justify-center items-center">
    <img src={TeamMember3} alt="team members" />
  </div>
  <div className=" rounded-xl flex justify-center items-center">
    <img src={TeamMember4} alt="team members" />
  </div>
  <div className=" rounded-xl flex justify-center items-center">
    <img src={TeamMember5} alt="team members" />
  </div>
</div>

    </div>



    <div className=
    {`px-5 md:px-14 flex flex-col ${theme === 'light' ? ' bg-gradient-to-b from-cyan-50 via-cyan-100 bg-[#fff]' : 'bg-[#0076D0]'}`}>

<div className={`flex w-full flex-col-reverse md:flex-row items-center py-10 ${theme === "light" ? "text-black" : "text-white"} `}>
          <div className="w-full md:w-4/6  justify-center items-center ">
            <p className="text-3xl md:text-[48px] font-semibold leading-130 pt-5 md:pt-0">Want to join our team?</p>
            <p className={`text-base leading-180 pt-4 pb-2`}>Our creative team is looking for you.</p>

            <div onClick={handleClick} className=
            {`h-12 w-44 rounded-full cursor-pointer hover:opacity-70 mt-4 ${theme === 'light' ? ' bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] p-[1px]' : 'border border-white text-white'}`}>
            <div className="flex h-full w-full rounded-full items-center justify-center back text-white">
              <h1 className="text-sm font-medium">Get Started</h1>{" "}
              <span>
                <div className="ml-10">
                  <ArrowRightIcon className="h-5 w-5 text-white" />
                </div>
              </span>
            </div>
            </div>
          </div>
          <div className="w-full md:w-2/6  flex justify-center items-center">
            <img src={JoinTeam} alt="join team" />
          </div>
        </div>
        </div>

    <div className='p-5 md:p-14 flex flex-col md:flex-row'>
    <div className="grid grid-rows-1 grid-cols-1 md:grid-rows-2 md:grid-cols-3 gap-8 w-full">
  <div className={`flex flex-col items-center lg:items-start ${theme === 'light' ? 'text-black' : ' text-white'}`}>
    <div className='flex items-center'>
  <div className="w-4 h-4 rounded-full bg-[#0076D0] mr-2 md:block hidden"> </div>
  <h4 className='text-base md:text-xl lg:text-2xl font-semibold leading-[150%] '>Our Advisory Board</h4>
  </div>
  <div className='w-full xl:w-1/2 text-2xl md:text-4xl xl:text-5xl font-semibold leading-130 pt-2 md:pt-4 md:text-left text-center'>
  Meet our 
Visionaries
  </div>
  <div className="w-10 h-1 rounded-full bg-[#0076D0] mr-2 block md:hidden mt-3"> </div>
  </div>
  <div className="rounded-xl flex justify-center items-center">
    <img src={AdMember1} alt="advisory members" />
  </div>
  <div className=" rounded-xl flex justify-center items-center">
    <img src={AdMember2} alt="advisory members" />
  </div>

  <div className=" rounded-xl flex justify-center items-center">
    <img src={AdMember3} alt="advisory members" />
  </div>
  <div className=" rounded-xl flex justify-center items-center">
    <img src={AdMember4} alt="advisory members" />
  </div>
  <div className=" rounded-xl flex justify-center items-center">
    <img src={AdMember5} alt="advisory members" />
  </div>
</div>

    </div>

    </div>
  )
}

export default Ourteam