// HeaderNav.js
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate} from 'react-router-dom';
import Logo from '../../Assests/Images/Logo';
import { useTheme } from '../../ThemeProvider';
import ThemeSwitch from './ThemeSwitch';
import MenuIcon from '../../Assests/Images/MenuIcon';

const HeaderNav = () => {
  const { theme } = useTheme();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();


  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const handleClick = () => {
    window.location.href = "https://master.d21w7m43snw0qn.amplifyapp.com/" 
  };



  return (
    <div className={`px-5 md:px-14 py-2 flex justify-between items-center h-24 relative ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d9] text-white'}`}>
      <div className="mb-4 md:mb-0">
        <Logo />
      </div>

      {/* Desktop Menu */}
      <div className={`flex items-center space-x-6 text-sm font-semibold mb-4 lg:mb-0 lg:ml-4 hidden lg:block menu-items ${isMenuOpen ? 'block' : ''}`}>
        <NavLink to='/' className='border-b-2 border-transparent px-2 hover:text-[#0076D0]' onClick={() => setMenuOpen(false)}>
          Home
        </NavLink>
        <NavLink to='/aboutus' className='border-b-2 border-transparent px-2 hover:text-[#0076D0]' onClick={() => setMenuOpen(false)}>
          About Us
        </NavLink>
        <NavLink to='/portfolio' className='border-b-2 border-transparent px-2 hover:text-[#0076D0]' onClick={() => setMenuOpen(false)}>
          Portfolio
        </NavLink>
        <NavLink to='/team' className='border-b-2 border-transparent px-2 hover:text-[#0076D0]' onClick={() => setMenuOpen(false)}>
          Our Team
        </NavLink>
        <NavLink to='/contact' className='border-b-2 border-transparent px-2 hover:text-[#0076D0]' onClick={() => setMenuOpen(false)}>
          Contact Us
        </NavLink>
        {/* <NavLink to='/login' className='border-b-2 border-transparent px-2 hover:text-[#0076D0]' onClick={() => setMenuOpen(false)}>
          Log in
        </NavLink> */}
      </div>

      {/* Desktop Get Started Button and Theme Switch */}
      <div className='hidden lg:block'>
        <div className='flex gap-2'>
          <div className="h-12 w-12 rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer">
            <div className={`flex h-full w-full rounded-full items-center justify-center ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <ThemeSwitch />
            </div>
          </div>
          <div onClick={handleClick} className="h-12 w-36 rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70">
            <div className={`flex h-full w-full rounded-full items-center justify-center back ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="text-sm font-medium">Get Started</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu Icon and Theme Switch */}
      <div className='lg:hidden flex items-center'>
        <ThemeSwitch />
        <button className="ml-4 focus:outline-none" onClick={handleToggleMenu}>
          {isMenuOpen ? <MenuIcon /> : <MenuIcon />}
        </button>
      </div>
{isMenuOpen && (
      <div  className={`lg:hidden bg-white top-full left-0 absolute  w-full  transition-all duration-300 py-5 shadow-2xl rounded-b-xl border-t border-[#0df1d4]`}>
        <NavLink to='/' className='block py-2  text-black hover:bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] hover:text-white px-5' onClick={() => setMenuOpen(false)}>
          Home
        </NavLink>
        <NavLink to='/aboutus' className='block py-2  text-black hover:bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] hover:text-white px-5' onClick={() => setMenuOpen(false)}>
          About Us
        </NavLink>
        <NavLink to='/portfolio' className='block py-2  text-black hover:bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] hover:text-white px-5' onClick={() => setMenuOpen(false)}>
          Portfolio
        </NavLink>
        <NavLink to='/team' className='block py-2  text-black hover:bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] hover:text-white px-5' onClick={() => setMenuOpen(false)}>
          Our Team
        </NavLink>
        <NavLink to='/contact' className='block py-2 text-black hover:bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] hover:text-white px-5' onClick={() => setMenuOpen(false)}>
          Contact
        </NavLink>
        <div className='flex justify-center py-5'>
          <div onClick={handleClick} className="h-12 w-44 rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer ">
            <div className={`flex h-full w-full rounded-full items-center justify-center hover:bg-gradient-to-r hover:from-[#0076D0] hover:to-[#0EFED4] hover:text-white ${theme === 'light' ? 'bg-white text-gray-700' : 'bg-[#010205d6] text-white'}`}>
              <h1 className="text-sm font-medium">Get Started</h1>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default HeaderNav;
