import React from 'react'

const ArrowImg = () => {
  return (
    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 13L-1.14193e-06 25.1244L-8.1987e-08 0.875644L21 13Z" fill="url(#paint0_linear_138_8073)"/>
    <defs>
    <linearGradient id="paint0_linear_138_8073" x1="21" y1="-1" x2="19.3879" y2="30.1899" gradientUnits="userSpaceOnUse">
    <stop offset="0.349967" stop-color="#0076D0"/>
    <stop offset="1" stop-color="#0EFED4"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default ArrowImg
