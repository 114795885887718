import React from 'react';
import PortFolio1 from '../../Assests/Images/Portfolio/portfolio1.png'
import PortFolio2 from '../../Assests/Images/Portfolio/portfolio2.png'
import PortFolio3 from '../../Assests/Images/Portfolio/portfolio3.png'
import PortFolio4 from '../../Assests/Images/Portfolio/portfolio4.png'
import PortFolio5 from '../../Assests/Images/Portfolio/portfolio5.png'
import PortFolio6 from '../../Assests/Images/Portfolio/portfolio6.png'

import { Link, useNavigate } from 'react-router-dom';


const PortfolioGrid = () => {
    const navigate = useNavigate();

    const handleClick = () => {
      // Navigate to the new detailed router page
      navigate('/portfolio/portfolio-details'); // Update the path accordingly
    };
  return (
    <div className="grid grid-rows-1 grid-cols-1 md:grid-rows-2 md:grid-cols-3 gap-2 space-y-3 w-full mt-10">
       
        <div className="rounded-xl flex justify-center items-center relative" onClick={handleClick}>
          <img src={PortFolio1} alt={`Portfolio `} className="w-full" />
          <div className="absolute w-full h-full">
            <div className="flex flex-col h-full w-full justify-between p-8 text-white">
              <div className="flex items-center">
                <div className="h-[2px] w-10 bg-white mr-3 rounded-full"></div>
                <span className="text-xs md:text-sm leading-[160%] capitalize">Gaskon, 2023</span>
              </div>
              <div className="text-sm md:text-lg leading-[170%] font-bold">Fueling Growth: How We Helped Dynamic Solutions Expand by 26%</div>
            </div>
          </div>
        </div>
        

        <div className="rounded-xl flex justify-center items-center relative" onClick={handleClick}>
          <img src={PortFolio2} alt={`Portfolio `} className="w-full" />
          <div className="absolute w-full h-full">
            <div className="flex flex-col h-full w-full justify-between p-8 text-white">
              <div className="flex items-center">
                <div className="h-[2px] w-10 bg-white mr-3 rounded-full"></div>
                <span className="text-xs md:text-sm leading-[160%] capitalize">Gaskon, 2023</span>
              </div>
              <div className="text-sm md:text-lg leading-[170%] font-bold">Strategic Boost: Partnering with Stellar Innovations for a 25% Growth Surge</div>
            </div>
          </div>
        </div>

        <div className="rounded-xl flex justify-center items-center relative" onClick={handleClick}>
          <img src={PortFolio3} alt={`Portfolio `} className="w-full" />
          <div className="absolute w-full h-full">
            <div className="flex flex-col h-full w-full justify-between p-8 text-white">
              <div className="flex items-center">
                <div className="h-[2px] w-10 bg-white mr-3 rounded-full"></div>
                <span className="text-xs md:text-sm leading-[160%] capitalize">Gaskon, 2023</span>
              </div>
              <div className="text-sm md:text-lg leading-[170%] font-bold">Financial Revitalization: Empowering
Quantum Enterprises to Increase Profit 
Margins by 20%</div>
            </div>
          </div>
        </div>


        <div className="rounded-xl flex justify-center items-center relative" onClick={handleClick}>
          <img src={PortFolio4} alt={`Portfolio `} className="w-full" />
          <div className="absolute w-full h-full">
            <div className="flex flex-col h-full w-full justify-between p-8 text-white">
              <div className="flex items-center">
                <div className="h-[2px] w-10 bg-white mr-3 rounded-full"></div>
                <span className="text-xs md:text-sm leading-[160%] capitalize">Gaskon, 2023</span>
              </div>
              <div className="text-sm md:text-lg leading-[170%] font-bold">Market Mastery: Guiding Fusion
Technologies to Attain a 35% Increase in Market Share</div>
            </div>
          </div>
        </div>


        <div className="rounded-xl flex justify-center items-center relative" onClick={handleClick}>
          <img src={PortFolio5} alt={`Portfolio `} className="w-full" />
          <div className="absolute w-full h-full">
            <div className="flex flex-col h-full w-full justify-between p-8 text-white">
              <div className="flex items-center">
                <div className="h-[2px] w-10 bg-white mr-3 rounded-full"></div>
                <span className="text-xs md:text-sm leading-[160%] capitalize">Gaskon, 2023</span>
              </div>
              <div className="text-sm md:text-lg leading-[170%] font-bold">Digital Revolution: A 40% Growth 
Story for Nova Dynamics through
Innovative Solutions</div>
            </div>
          </div>
        </div>


        <div className="rounded-xl flex justify-center items-center relative" onClick={handleClick}>
          <img src={PortFolio6} alt={`Portfolio `} className="w-full" />
          <div className="absolute w-full h-full">
            <div className="flex flex-col h-full w-full justify-between p-8 text-white">
              <div className="flex items-center">
                <div className="h-[2px] w-10 bg-white mr-3 rounded-full"></div>
                <span className="text-xs md:text-sm leading-[160%] capitalize">Gaskon, 2023</span>
              </div>
              <div className="text-sm md:text-lg leading-[170%] font-bold">Sustainable Success: Our Collaboration 
with Zenith Ventures Resulted in a 28% Revenue Upswing</div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default PortfolioGrid;
