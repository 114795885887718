import React from 'react';
import { SunIcon } from '@heroicons/react/24/solid';
import { useTheme } from '../../ThemeProvider';

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div
      className="cursor-pointer"
      onClick={toggleTheme}
    >
      {theme === 'dark' ? (
        <div className=" h-10 w-10 transform rounded-full bg-[#0076D0] shadow-sm">
          <span className="absolute inset-0 flex h-full w-full items-center justify-center">
            <SunIcon className="h-6 w-6 text-[#fff]" />
          </span>
        </div>
      ) : (
        <div className=" h-10 w-10 transform rounded-full bg-white shadow-sm">
          <span className="absolute inset-0 flex h-full w-full items-center justify-center">
         <SunIcon className="h-6 w-6 text-[#010205d6]" />
         </span>
        </div>
      )}
    </div>
  );
};

export default ThemeSwitch;
