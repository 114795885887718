import React from "react";
import { useTheme } from "../ThemeProvider";
import Logo1 from "../Assests/Images/Companies/image1.png";
import Logo2 from "../Assests/Images/Companies/image2.png";
import Logo3 from "../Assests/Images/Companies/image3.png";
import Logo4 from "../Assests/Images/Companies/image4.png";
import Logo5 from "../Assests/Images/Companies/image5.png";

import BannerLight from "../Assests/Images/banner_img.png";
import BannerDark from "../Assests/Images/banner_bg.png";
import OpportunityImg from "../Assests/Images/aboutusImg.png";
import ApprochImg from "../Assests/Images/approchImg.png";
import DiscoveryOpportunity from "../Assests/Images/discoveryOpportunity.png";
import StrategyOppertunity from "../Assests/Images/strategyOppertunity.png";
import ExecutionOpportunity from "../Assests/Images/executionOpportunity.png";
import MeassurementOpportunity from "../Assests/Images/meassurementOpportunity.png";
import ArrowImg from "../Assests/Images/ArrowImg";
import footer from "../Assests/Images/bg-texture.png";

import TestimonialAboutusSlider from "./Components/TestMonials/TestimonialAboutus";


const testimonials = [
  { imgURL: require('../Assests/Images/AboutUsImg/testimoniAboutus.png'), author: 'Sara Taylor', title:'CEO of Basecamp Corp', testmony:"“ Gaskon Consulting's insightful analysis of our industry and target audience has been instrumental in crafting tailored campaigns that seamlessly reach and captivate our customers. Their innovative ideas and state-of-the-art techniques have consistently propelled us ahead of the competition." },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Sara Taylor', title:'CEO of Basecamp Corp', testmony:"“ Gaskon Consulting's insightful analysis of our industry and target audience has been instrumental in crafting tailored campaigns that seamlessly reach and captivate our customers. Their innovative ideas and state-of-the-art techniques have consistently propelled us ahead of the competition.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  { imgURL: 'https://cdn4.iconfinder.com/data/icons/BRILLIANT/accounting/png/400/executive.png', author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
];

const AboutUs = () => {
  const { theme } = useTheme();
  return (
    <div className={`flex flex-col `}>
      <div
        className="flex flex-col  pt-[8rem]  bg-no-repeat bg-cover"
        style={{
          backgroundImage:
            theme === "light" ? `url(${BannerLight})` : `url(${BannerDark})`,
        }}
      >
        <h1
          className={`text-xl md:text-2xl lg:text-5xl font-semibold leading-130 text-center  px-5 lg:px-14 ${
            theme === "light" ? "text-black" : "text-white"
          }`}
        >
          Navigating Vast Business Horizons and Unlocking Limitless
          Opportunities with Gaskon Consulting's Strategic Brilliance and
          Innovative Solutions
        </h1>
        <div className=" md:px-14">
          <img
            src={OpportunityImg}
            className="bg-no-repeat bg-cover md:rounded-3xl mt-6 w-full"
          />

          <p
            className={`font-medium text-sm lg:text-lg leading-[150%] py-5 px-5 lg:px-14  w-full text-center ${
              theme === "light" ? "text-black" : "text-[#C3C3C3]"
            }`}
          >
            Discover a realm of unparalleled business potential on our Business
            Opportunities. At Gaskon Consulting, we redefine success by offering
            strategic brilliance and innovative solutions. Navigate vast
            horizons, seize limitless opportunities, and transform your business
            future with us. Explore the possibilities and chart your course to
            prosperity.
          </p>
        </div>
      </div>
      <div
        className={`flex flex-col py-12 px-5 md:px-14 ${
          theme === "light" ? "bg-white" : "bg-black"
        }`}
      >
        <p
          className={`text-center text-[30px] md:text-5xl font-semibold leading-130 ${
            theme === "light" ? "text-black" : "text-white"
          }`}
        >
          Companies we Work with
        </p>

        <div className="grid grid-cols-2 sm:grid-cols-6 md:grid-cols-5 grid-rows-3 sm:grid-rows-1 gap-10 md:gap-4 mt-10">
          <img
            src={Logo1}
            className="w-[121px] md:w-[190px] md:col-span-1 self-center justify-self-center"
            alt=""
          />
          <img
            src={Logo2}
            className="w-[121px] md:w-[190px] md:col-span-1 self-center justify-self-center"
            alt=""
          />
          <img
            src={Logo3}
            className="w-[121px] md:w-[190px] md:col-span-1 self-center justify-self-center"
            alt=""
          />
          <img
            src={Logo4}
            className="w-[121px] md:w-[190px] md:col-span-1 self-center justify-self-center"
            alt=""
          />
          <img
            src={Logo5}
            className="w-[121px] md:w-[190px] md:col-span-1 col-span-2 self-center justify-self-center"
            alt=""
          />
        </div>
      </div>

      <div className={`flex flex-col md:flex-row px-5 lg:px-14 ${theme === "light" ? "bg-gradient-to-b from-[#D4F2FF] via-[#D4F2FF] to-[#FAFAFA]" : "bg-[#0076D0]"} `}>
        <div className="flex flex-col w-full md:w-2/5 py-5 px-0 md:py-16 md:px-10">
          <h1 className={`text-[30px] md:text-5xl leading-130 font-semibold ${theme === "light" ? "text-black" : "text-white"}`}>
            Our Approach
          </h1>
          <p className={`text-sm md:text-lg leading-180 font-medium opacity-60 pb-5 pt-2 ${theme === "light" ? "text-black" : "text-white"}`}>
            Unearth the impact of Strategic Brilliance, foster Innovation, and
            achieve Optimized Success with Gaskon as your trusted Business
            Consulting Partner.
          </p>

          <img src={ApprochImg} alt="" className="rounded-3xl" />
        </div>

        <div className="grid grid-col-4 gap-8 w-full md:w-3/5 p-0 py-5 px-0 md:py-16 md:px-10">
          <div className="flex items-center">
            <img
              src={DiscoveryOpportunity}
              alt=""
              className="md:w-[100px] w-[40px]"
            />
            <div className="flex flex-col pl-3">
              <h3 className={`${theme === "light" ? "text-black" : "text-white"} text-base md:text-2xl font-semibold  leading-[150%]`}>
                Discovery
              </h3>
              <span className={`text-sm md:text-lg  leading-[150%] opacity-60  font-medium ${theme === "light" ? "text-black" : "text-white"}`}>
                We meet with you to learn about your business, your goals, and
                your target audience.
              </span>
            </div>
          </div>

          <div className="flex  items-center">
            <img
              src={StrategyOppertunity}
              alt=""
              className="md:w-[100px] w-[40px]"
            />
            <div className="flex flex-col pl-3">
              <h3 className={`${theme === "light" ? "text-black" : "text-white"} text-base md:text-2xl font-semibold leading-[150%]`}>
                Strategy
              </h3>
              <span className={`${theme === "light" ? "text-black" : "text-white"} text-sm md:text-lg leading-[150%]opacity-60  font-medium`}>
                We develop a customized business strategy that is based on your
                unique needs and goals.
              </span>
            </div>
          </div>

          <div className="flex  items-center">
            <img
              src={ExecutionOpportunity}
              alt=""
              className="md:w-[100px] w-[40px]"
            />
            <div className="flex flex-col pl-3">
              <h3 className={`${theme === "light" ? "text-black" : "text-white"} text-base md:text-2xl font-semibold leading-[150%]`}>
                Execution
              </h3>
              <span className={`${theme === "light" ? "text-black" : "text-white"} text-sm md:text-lg leading-[150%] opacity-60  font-medium`}>
                We execute our strategy using the latest digital growth tools
                and techniques.
              </span>
            </div>
          </div>

          <div className="flex  items-center">
            <img
              src={MeassurementOpportunity}
              alt=""
              className="md:w-[100px] w-[40px]"
            />
            <div className="flex flex-col pl-3">
              <h3 className={`${theme === "light" ? "text-black" : "text-white"} text-base md:text-2xl font-semibold leading-[150%]`}>
                Measurement
              </h3>
              <span className={`${theme === "light" ? "text-black" : "text-white"} text-sm md:text-lg  leading-[150%] opacity-60  font-medium`}>
                We track the results of our campaigns so that we can make
                adjustments as needed.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col px-5 lg:px-14 py-5 ${
          theme === "light" ? "bg-white" : "bg-black"
        }`}
      >
        <h1
          className={`md:text-center text-[30px] md:text-5xl font-semibold leading-130 ${
            theme === "light" ? "text-black" : "text-white"
          }`}
        >
          {" "}
          Funding Raised{" "}
        </h1>

        <div className="flex flex-col md:flex-row pt-16">
          <div className="flex flex-col items-center w-full md:w-1/3">
            <div className="flex flex-col">
              <div className="flex flex-col justify-center md:items-center items-start">
              <div className="flex items-baseline">
                <span
                  className={`text-[60px] md:text-7xl font-semibold leading-[110%] ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  200
                </span>{" "}
                <span
                  className={`text-[60px] md:text-7xl font-semibold  ${
                    theme === "light" ? "text-[#0076D0]" : "text-[#0076D0]"
                  }`}
                >
                  K
                </span>{" "}
                <span
                  className={`text-2xl font-semibold flex items-end pl-2 leading-[150%] ${
                    theme === "light" ? "text-[#9B9B9C]" : "text-white"
                  }`}
                >
                  USD
                </span>
              </div>
              
              <span
                className={`text-sm md:text-lg font-medium leading-[150%] capitalize pt-1 ${
                  theme === "light" ? "text-[#9B9B9C]" : "text-white"
                }`}
              >
                may, 2021
              </span>
              </div>
              
              <div className="w-full flex flex-col md:flex-row md:pl-4 md:mt-6 md:border-t-[8px]  pt-6 md:py-6 border-[#F7F7F7] relative mt-6">
                <div
                  className={` rounded-full w-[49px] h-[49px] flex items-center justify-center  ${
                    theme === "light" ? "bg-[#F7F7F7]" : "bg-[#0076D0]"
                  }`}
                >
                  <span
                    className={` font-semibold text-[32px] ${
                      theme === "light" ? "text-[#9B9B9C]" : "text-white"
                    }`}
                  >
                    1
                  </span>
                </div>

                <div className="flex flex-col mt-4 mb-4 md:mb-0 md:mt-16 w-11/12 md:w-4/5">
                  <h4
                    className={` font-semibold text-[23px] md:text-2xl leading-[150%] ${
                      theme === "light" ? "text-[#0076D0]" : "text-white"
                    }`}
                  >
                    Embarking on the Fundraising Odyssey
                  </h4>

                  <h6
                    className={` font-medium pt-3 text-sm md:text-base leading-[150%] ${
                      theme === "light" ? "text-[#878C91]" : "text-white"
                    }`}
                  >
                    Embark on a journey of ambition as we initiate our
                    fundraising odyssey, igniting the flame for our visionary
                    goals.
                  </h6>
                </div>
                <div className="absolute -top-[1.1rem] -right-4 z-10 md:block hidden">
              <ArrowImg />
            </div>
              </div>

            </div>

           
          </div>

          <div className="flex flex-col items-center w-full md:w-1/3  pb-6 ">
            <div className="flex flex-col ">
              <div className="flex flex-col justify-center md:items-center items-start">
              <div className="flex items-baseline">
                <span
                  className={`text-[60px] md:text-7xl font-semibold leading-[110%] ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  1.6
                </span>{" "}
                <span
                  className={`text-[60px] md:text-7xl font-semibold  ${
                    theme === "light" ? "text-[#0076D0]" : "text-[#0076D0]"
                  }`}
                >
                  M
                </span>{" "}
                <span
                  className={`text-2xl font-semibold flex items-end pl-2 leading-[150%] ${
                    theme === "light" ? "text-[#9B9B9C]" : "text-white"
                  }`}
                >
                  USD
                </span>
              </div>
              <span
                className={`text-sm md:text-lg font-medium flex items-end leading-[150%] capitalize pt-1 ${
                  theme === "light" ? "text-[#9B9B9C]" : "text-white"
                }`}
              >
                November, 2022
              </span>
              </div>
              <div className="w-full flex flex-col md:flex-row md:pl-4 md:mt-6 md:border-t-[8px]  pt-6 md:py-6 border-[#F7F7F7] relative">
              <div
              className={` rounded-full w-[49px] h-[49px] flex items-center justify-center ${
                theme === "light" ? "bg-[#F7F7F7]" : "bg-[#0076D0]"
              }`}
            >
              <span
                className={` font-semibold text-[32px] ${
                  theme === "light" ? "text-[#9B9B9C]" : "text-white"
                }`}
              >
                2
              </span>
            </div>
            <div className="flex flex-col mt-4 mb-4 md:mb-0 md:mt-16 w-11/12 md:w-4/5">
              <h4
                className={` font-semibold text-[23px] md:text-2xl leading-[150%] ${
                  theme === "light" ? "text-[#0076D0]" : "text-white"
                }`}
              >
                Achieving Milestones in our Fundraising Ascent
              </h4>

              <h6
                className={` font-medium pt-3 text-sm md:text-base leading-[150%] ${
                  theme === "light" ? "text-[#878C91]" : "text-white"
                }`}
              >
                Witness the heights we reach, celebrating milestones in our
                fundraising ascent with each successful step towards realizing
                our ambitions.
              </h6>
            </div>
                
            <div className="absolute -top-[1.1rem] -right-4 z-10 md:block hidden">
              <ArrowImg />
            </div>
              </div>
            </div>

           
          </div>

          <div className="flex flex-col items-center w-full md:w-1/3  pb-6">
            <div className="flex flex-col w-full">
            <div className="flex flex-col justify-center md:items-center items-start">
              <div className="flex items-baseline">
                <span
                  className={`text-[60px] md:text-7xl font-semibold leading-[110%] ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  4.8
                </span>{" "}
                <span
                  className={`text-[60px] md:text-7xl font-semibold  ${
                    theme === "light" ? "text-[#0076D0]" : "text-[#0076D0]"
                  }`}
                >
                  M
                </span>{" "}
                <span
                  className={`text-2xl font-semibold flex items-end pl-2 leading-[150%] ${
                    theme === "light" ? "text-[#9B9B9C]" : "text-white"
                  }`}
                >
                  USD
                </span>
              </div>
              <span
                className={`text-sm md:text-lg font-medium flex items-end leading-[150%] capitalize pt-1 ${
                  theme === "light" ? "text-[#9B9B9C]" : "text-white"
                }`}
              >
                August, 2023
              </span>
              </div>
              <div className="w-full flex flex-col md:flex-row md:pl-4 md:mt-6 md:border-t-[8px]  pt-6 md:py-6 border-[#F7F7F7] relative">
              <div
              className={` rounded-full w-[49px] h-[49px] flex items-center justify-center ${
                theme === "light" ? "bg-[#F7F7F7]" : "bg-[#0076D0]"
              }`}
            >
              <span
                className={` font-semibold text-[32px] ${
                  theme === "light" ? "text-[#9B9B9C]" : "text-white"
                }`}
              >
                3
              </span>
            </div>
            <div className="flex flex-col mt-4 mb-4 md:mb-0 md:mt-16 w-11/12 md:w-4/5">
              <h4
                className={` font-semibold text-[23px] md:text-2xl leading-[150%] ${
                  theme === "light" ? "text-[#0076D0]" : "text-white"
                }`}
              >
                Triumph at the Pinnacle of Success
              </h4>

              <h6
                className={` font-medium pt-3 text-sm md:text-base leading-[150%] ${
                  theme === "light" ? "text-[#878C91]" : "text-white"
                }`}
              >
                Celebrating the Completion of Our Fully Funded Ambitions and
                Setting New Horizons
              </h6>
            </div>
            </div>
            </div>
          </div>
        </div>

      </div>

      <div className='bg-cover bg-no-repeat px-5 lg:px-14' style={{
        backgroundImage: theme === "light" ? `url(${footer})` : `url(${footer})`,
      }}>
<TestimonialAboutusSlider testimonials={testimonials} />
</div>
    </div>
  );
};

export default AboutUs;
