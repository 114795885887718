import React from 'react'

const XIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1626 0.5H14.3679L9.54994 6.00667L15.2179 13.5H10.7799L7.30394 8.95533L3.3266 13.5H1.11994L6.27327 7.61L0.835938 0.5H5.3866L8.5286 4.654L12.1626 0.5ZM11.3886 12.18H12.6106L4.7226 1.75067H3.41127L11.3886 12.18Z" fill="black"/>
    </svg>
    
  )
}

export default XIcon
