import React, { InputHTMLAttributes } from 'react'
export interface Props<T = unknown>
  extends InputHTMLAttributes<HTMLInputElement> {
  width: string;
  height: string;
}

const CheckTickIcon = ({
  width, height
}: Props): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="12" fill="#0076D0"/>
    <path d="M21.3337 12L14.0003 19.3333L10.667 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default CheckTickIcon