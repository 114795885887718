import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextButton from "../../../Assests/Images/next-button.svg";
import PrevButton from "../../../Assests/Images/prev-button.svg";
import { useTheme } from "../../../ThemeProvider";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
interface Testimonial {
  imgURL: string;
  author: string;
  title: string;
  testmony: string;
}

interface TestimonialAboutusProps {
  testimonials: Testimonial[];
}

const TestimonialAboutusSlider: React.FC<TestimonialAboutusProps> = ({
  testimonials,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current: number) => setCurrentIndex(current),
  };
  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const { theme } = useTheme();

  return (
    <div className="relative mx-4 mt-8 md:m-0">
      <Slider ref={sliderRef} {...settings}>
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`px-4 py-6 md:my-5  bg-white p-4 rounded-2xl md:rounded-none md:bg-transparent ${
              theme === "light" ? "" : "bg-white md:bg-transparent"
            }`}
          >
            <div className="flex md:flex-col flex-col-reverse">
              <div className="md:min-h-[100px] md:max-h-[300px]">
                <p
                  className={`md:text-[32px]  text-base text-[#666A6F] md:text-black md:py-2 leading-[140%]  md:leading-[150%]'
           ${
             theme === "light"
               ? "md:font-semibold font-semibold"
               : "font-semibold md:font-medium "
           }`}
                >
                  {testimonial.testmony}
                </p>
              </div>

              <div className="flex justify-between items-center md:mt-8 mb-4 md:mb-0">
                <div className="flex items-center">
                  <div>
                    <img
                      src={testimonial.imgURL}
                      alt="profileimg"
                      className="w-[70px] h-[70px] rounded-full border border-white"
                    />
                  </div>
                  <div className="flex flex-col pl-2">
                    <span
                      className={`text-lg font-bold   capitalize ${
                        theme === "light"
                          ? "md:text-black text-[#0076D0] "
                          : "text-[#0076D0]"
                      }`}
                    >
                      {testimonial.author}
                    </span>
                    <span className="text-sm md:text-base text-[#666A6F]">
                      {testimonial.title}
                    </span>
                  </div>
                </div>

                <div className="md:block hidden">
                  <div className="flex justify-between items-center mt-1">
                    <button
                      type="button"
                      className="border border-black h-9 w-12 justify-center flex items-center rounded-full"
                      onClick={handlePrevClick}
                    >
                      <ArrowLeftIcon className="w-6 h-6" />
                    </button>
                    <div className="  text-[#010205] text-base font-semibold px-10">
                      <span className="underline">0{currentIndex + 1}</span>{" "}
                      <span className=" opacity-40">
                        / 0{testimonials.length}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="border border-black h-9 w-12 justify-center flex items-center rounded-full"
                      onClick={handleNextClick}
                    >
                      <ArrowRightIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ))}
      </Slider>
      <div className="md:hidden block my-6">
        <div className="flex justify-between items-center  mb-2">
          <button type="button" onClick={handlePrevClick}>
            <img className="w-10" src={PrevButton} alt="prev-button" />
          </button>
          <div className="  text-[#010205] text-base font-semibold">
            <span className="underline">0{currentIndex + 1}</span>{" "}
            <span className=" opacity-40">/ 0{testimonials.length}</span>
          </div>
          <button type="button" onClick={handleNextClick}>
            <img className="w-10" src={NextButton} alt="next-button" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialAboutusSlider;
