import React, { InputHTMLAttributes } from 'react'
import { useTheme } from '../../ThemeProvider';
export interface Props<T = unknown>
  extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  bgColor:string;
}

const ButtonTab = ({
    title, bgColor
  }: Props): JSX.Element => {
    const { theme } = useTheme();

  return (
    <div className="h-12 w-max rounded-full bg-gradient-to-r from-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer">
    <div className={`flex h-full w-full rounded-full items-center justify-center hover:bg-gradient-to-r from-[#0076D0] to-[#0EFED4]  ${theme === 'light' ? `${bgColor} ` : 'bg-[#010205d6] text-white'}`}>
      <h1 className="text-sm font-medium px-5">{title}</h1>
    </div>
  </div>

  )
}

export default ButtonTab
