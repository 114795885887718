import React from 'react'

const RoundShapeIcon = () => {
  return (
    <svg width="196" height="196" viewBox="0 0 196 196" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_44_1212)">
<rect x="44" y="14" width="108" height="108" rx="54" fill="white"/>
</g>
<path d="M121 57L102 76L92 66L77 81" stroke="url(#paint0_linear_44_1212)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M109 57H121V69" stroke="url(#paint1_linear_44_1212)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<defs>
<filter id="filter0_d_44_1212" x="0.253164" y="0.329115" width="195.494" height="195.494" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="6.83544" operator="erode" in="SourceAlpha" result="effect1_dropShadow_44_1212"/>
<feOffset dy="30.076"/>
<feGaussianBlur stdDeviation="25.2911"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_44_1212"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_44_1212" result="shape"/>
</filter>
<linearGradient id="paint0_linear_44_1212" x1="77" y1="57" x2="125.706" y2="61.6153" gradientUnits="userSpaceOnUse">
<stop offset="0.349967" stopColor="#0076D0"/>
<stop offset="1" stopColor="#0EFED4"/>
</linearGradient>
<linearGradient id="paint1_linear_44_1212" x1="109" y1="57" x2="122.367" y2="57.6909" gradientUnits="userSpaceOnUse">
<stop offset="0.349967" stopColor="#0076D0"/>
<stop offset="1" stopColor="#0EFED4"/>
</linearGradient>
</defs>
</svg>

  )
}

export default RoundShapeIcon