import React from 'react';
import { useTheme } from '../../../ThemeProvider';

interface Testimonial {
  imgURL: string;
  author: string;
  title: string;
  testmony:string;
}

interface TestMonialGridProps {
  testimonials: Testimonial[];
}

const TestMonialGrid: React.FC<TestMonialGridProps> = ({ testimonials }) => {
    const { theme } = useTheme();
  return (
   
    <div className="grid grid-cols-3 gap-x-6 items-center" >
      <div className='flex flex-col gap-y-6'>
      {testimonials.filter((_,index)=>index%3 === 0).map((testimonial, index) => (
        <div key={index} className="p-4 shadow-lg rounded-lg h-auto ">
          <div className='flex flex-col'>
            <div className='flex '>
<div>
    <img src={testimonial.imgURL} alt="profileimg" className='w-[70px] h-[70px] rounded-full' />
</div>
<div className='flex flex-col pl-2'>
<span className='text-[22px] font-semibold text-[#0076D0] capitalize'>{testimonial.author}</span>
<span className='text-base text-[#666A6F]'>{testimonial.title}</span>
</div>
            </div>
            <p className='text-base text-[#666A6F] py-2'>{testimonial.testmony}</p>
          </div>
          
        </div>
      ))}
      </div>
      <div className='flex flex-col gap-y-6'>
      {testimonials.filter((_,index)=>index%3 === 1).map((testimonial, index) => (
        <div key={index} className="p-4 shadow-lg rounded-lg h-auto ">
          <div className='flex flex-col'>
            <div className='flex '>
<div>
    <img src={testimonial.imgURL} alt="profileimg" className='w-[70px] h-[70px] rounded-full' />
</div>
<div className='flex flex-col pl-2'>
<span className='text-[22px] font-semibold text-[#0076D0] capitalize'>{testimonial.author}</span>
<span className='text-base text-[#666A6F]'>{testimonial.title}</span>
</div>
            </div>
            <p className='text-base text-[#666A6F] py-2'>{testimonial.testmony}</p>
          </div>
          
        </div>
      ))}
      </div>
      <div className='flex flex-col gap-y-6'>
      {testimonials.filter((_,index)=>index%3 === 2).map((testimonial, index) => (
        <div key={index} className="p-4 shadow-lg rounded-lg h-auto ">
          <div className='flex flex-col'>
            <div className='flex '>
<div>
    <img src={testimonial.imgURL} alt="profileimg" className='w-[70px] h-[70px] rounded-full' />
</div>
<div className='flex flex-col pl-2'>
<span className='text-[22px] font-semibold text-[#0076D0] capitalize'>{testimonial.author}</span>
<span className='text-base text-[#666A6F]'>{testimonial.title}</span>
</div>
            </div>
            <p className='text-base text-[#666A6F] py-2'>{testimonial.testmony}</p>
          </div>
          
        </div>
      ))}
      </div>
    </div>
    
  );
};

export default TestMonialGrid;
