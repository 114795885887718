import React from 'react'
import { useTheme } from "../../ThemeProvider";
import ButtonTab from "./ButtonTab";
import imageBlackSvg from '../../Assests/Images/bg_black.svg';
import tabImg1 from "../../Assests/Images/TabImages/tab-images.png";
import tabImg2 from "../../Assests/Images/TabImages/tabImg2.png";
import tabImg3 from "../../Assests/Images/TabImages/tabImg2.png";



const MarketingObjectives = () => {
    const { theme } = useTheme();
  return (
    <div className={` ${theme === "light" ? "px-[30px] md:px-14 py-8" : "bg-cover bg-center"} `}
    style={theme !== "light" ? { backgroundImage: `url(${imageBlackSvg})` } : {}}>
      
       <div   className="relative bg-cover bg-center w-full h-full rounded-3xl flex flex-col py-10"
         
         style={theme !== "light" ? { backgroundImage: `` } : {backgroundImage: `url(${imageBlackSvg})`}}>

       
       <h1 className="text-[30px] md:text-[48px] font-semibold text-center text-white px-10">Real-world examples of Unveiling Gaskon Consulting's Impact on Fulfilling Marketing Objectives</h1>

       <div className="flex flex-col justify-center">

         <div className="flex flex-col md:flex-row justify-center mt-8 gap-4 px-10">
           
        <ButtonTab title={"All Work [20]"} bgColor={"bg-black text-white"} />
        <ButtonTab title={"Strategic Planning [10]"} bgColor={"bg-black text-white"} />
        <ButtonTab title={"Financial Advisory [5]"} bgColor={"bg-black text-white"} />
        <ButtonTab title={"Digital Marketing [5]"} bgColor={"bg-black text-white"} />
        <ButtonTab title={"SEO [6]"} bgColor={"bg-black text-white"} />


         </div>
<div className="flex gap-8 p-10 overflow-x-auto">
<img
                 className=" rounded-full"
                 src={tabImg1}
                 alt="tabImg1"
                 width={460}
                 height={460}
               />
               <img
                 className=" rounded-md"
                 src={tabImg2}
                 alt="tabImg2"
                 width={460}
                 height={460}
               />

<img
                 className=" rounded-md"
                 src={tabImg3}
                 alt="tabImg3"
                 width={460}
                 height={460}
               />
</div>
         </div>
       </div>

     </div>
  )
}

export default MarketingObjectives