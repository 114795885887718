import { ArrowRightIcon, EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import React from "react";
import getInTouch from "../../Assests/Images/getInTouch.png";
import blueInTouch from "../../Assests/Images/blueFooterImg.png";
import { useTheme } from "../../ThemeProvider";
import Logo from "../../Assests/Images/Logo";
import footer from "../../Assests/Images/bg-texture.png";
import FbIcon from "../../Assests/Images/FbIcon";
import XIcon from "../../Assests/Images/XIcon";
import LinkedinIcon from "../../Assests/Images/LinkedinIcon";
import InstaIcon from "../../Assests/Images/InstaIcon";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { theme } = useTheme();
  return (
    <div id="footer"
      className={`flex flex-col py-8 px-5 md:px-14 h-full bg-cover bg-no-repeat ${
        theme === "light" ? "bg-white" : "bg-black"
      }`}
      style={{
        backgroundImage: theme === "light" ? `url(${footer})` : `url(${""})`,
      }}
    >





      <div
        className="relative bg-cover bg-center w-full h-[300px]  rounded-[30px]   px-5 py-2"
        style={{
          backgroundImage:
            theme === "light" ? `url(${getInTouch})` : `url(${blueInTouch})`,
        }}
      >
        <div className="absolute inset-0  opacity-50 w-full h-full rounded-[30px] "></div>
        <div className="absolute inset-0 flex items-center justify-center w-full h-full">
          <div className="w-full h-full flex flex-col md:flex-row justify-center md:justify-between items-center px-5 lg:px-24">
            <span
              className={`text-4xl md:text-7xl font-semibold mb-8 md:mb-0  ${
                theme === "light" ? "text-white" : "text-white"
              }`}
            >
              Get In Touch
            </span>
            <button className="text-base font-semibold text-[#0076D0] flex justify-center items-center px-8 h-14 rounded-full bg-white">
              Schedule a Call
              <ArrowRightIcon className="h-5 w-5 text-[#0076D0] ml-10" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col w-full mt-10">
        <div className="w-full lg:w-1/2 flex flex-col">
          <Logo />
          <p
            className={`text-base font-medium pt-5 md:pr-16 ${
              theme === "light" ? "text-[#878C91]" : "text-white"
            }`}
          >
            Discover the Gaskon Consulting Advantage: Tailored Solutions,
            Strategic Excellence, and a Commitment to Your Success.
          </p>

          <div className="flex gap-3 mt-6">
            <div
              className={`flex justify-center items-center rounded-full w-9 h-9 ${
                theme === "light" ? "bg-white" : "bg-[#0076D0]"
              }`}
            >
              <FbIcon />
            </div>
            <div
              className={`flex justify-center items-center rounded-full w-9 h-9 ${
                theme === "light" ? "bg-white" : "bg-[#0076D0]"
              }`}
            >
              <XIcon />
            </div>
            <div
              className={`flex justify-center items-center rounded-full w-9 h-9 ${
                theme === "light" ? "bg-white" : "bg-[#0076D0]"
              }`}
            >
              <LinkedinIcon />
            </div>
            <div
              className={`flex justify-center items-center rounded-full w-9 h-9 ${
                theme === "light" ? "bg-white" : "bg-[#0076D0]"
              }`}
            >
              <InstaIcon />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex md:flex-row flex-col ">
          <div className="w-full md:w-1/3 flex-col md:mt-0 mt-6 ml-4 md:ml-0">
            <span
              className={`text-lg font-semibold lg:font-medium  ${
                theme === "light" ? "text-black" : "text-white"
              }`}
            >
              Navigation
            </span>
            <div
              className={`text-sm font-medium md:mt-4 mt-3 lg:mt-8  ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
              <NavLink to={""}> About Us </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
              <NavLink to={""}>Case Study </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
              <NavLink to={""}> Resource </NavLink>{" "}
            </div>
          </div>

          <div className="w-full md:w-1/3 flex-col md:mt-0 mt-6 ml-4 md:ml-0">
            <span
              className={`text-lg font-semibold lg:font-medium  ${
                theme === "light" ? "text-black" : "text-[#C3C3C3]"
              }`}
            >
              License
            </span>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 lg:mt-8  ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
              <NavLink to={""}> Privacy Policy </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
              <NavLink to={""}>Copyright </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
              <NavLink to={""}> Email Address </NavLink>{" "}
            </div>
          </div>
          <div className="w-full md:w-1/3 flex-col md:mt-0 mt-6 ml-4 md:ml-0">
            <span
              className={`text-lg font-semibold lg:font-medium  ${
                theme === "light" ? "text-black" : "text-[#C3C3C3]"
              }`}
            >
              Contact
            </span>
            <div
              className={`text-sm font-medium md:mt-4 mt-3 lg:mt-8  flex items-center ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
                <span className="mr-2"> <PhoneIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-[#0076D0]"
              }`} /> </span>
              <NavLink to={""}> 817-880-1304 </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 flex items-center ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
                <span className="mr-2"> <EnvelopeIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-[#0076D0]"
              }`} /> </span>
              <NavLink to={""}>gaskon.io </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium md:mt-4 mt-3 flex items-center ${
                theme === "light" ? "text-[#C3C3C3]" : "text-white"
              }`}
            >
                <span className="mr-2"><MapPinIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-[#0076D0]"
              }`} /></span>
              <NavLink to={""}> 2972 Westheimer Rd. Santa Ana, Illinois 85486  </NavLink>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
