import React from 'react'

const MenuIcon = () => {
  return (
    <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2H34" stroke="url(#paint0_linear_213_2236)" stroke-width="3" stroke-linecap="round"/>
<path d="M2 16H34" stroke="url(#paint1_linear_213_2236)" stroke-width="3" stroke-linecap="round"/>
<path d="M2 30H34" stroke="url(#paint2_linear_213_2236)" stroke-width="3" stroke-linecap="round"/>
<defs>
<linearGradient id="paint0_linear_213_2236" x1="2" y1="3.49999" x2="34" y2="3.49999" gradientUnits="userSpaceOnUse">
<stop stop-color="#0076D0"/>
<stop offset="1" stop-color="#0EFED4"/>
</linearGradient>
<linearGradient id="paint1_linear_213_2236" x1="2" y1="17.5" x2="34" y2="17.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#0076D0"/>
<stop offset="1" stop-color="#0EFED4"/>
</linearGradient>
<linearGradient id="paint2_linear_213_2236" x1="2" y1="31.5" x2="34" y2="31.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#0076D0"/>
<stop offset="1" stop-color="#0EFED4"/>
</linearGradient>
</defs>
</svg>

  )
}

export default MenuIcon
