// Example component
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import BannerRightImageTwo from "../Assests/Images/BannerRightImageTwo";
import HomeLogos from "../Assests/Images/HomeLogos";
import RoundShapeIcon from "../Assests/Images/RoundShapeIcon";
import { useTheme } from "../ThemeProvider";
import imageSrc  from '../Assests/Images/body_section_image.png';
import CheckTickIcon from "../Assests/Images/CheckTickIcon";
import CompanyGrowth from "./Components/CompanyGrowth";
import RoundShapeDarkIcon from "../Assests/Images/RoundShapeDarkIcon";
import BannerLight from "../Assests/Images/banner_img.png";
import BannerDark from "../Assests/Images/banner_bg.png";
import CurveImage from "../Assests/Images/CurveImage.png";
import TestMonialGrid from "./Components/TestMonials/TestMonialGrid";
import MarketingObjectives from "./Components/MarketingObjectives";
import TestMonialSlider from "./Components/TestMonials/TestMonialSlider";
import {useNavigate} from 'react-router-dom';



const Home = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const testimonials = [
    { imgURL: require('../Assests/Images/person-1.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-2.png'), author: 'Sara Taylor', title:'CEO of Basecamp Corp', testmony:"“ Gaskon Consulting's insightful analysis of our industry and target audience has been instrumental in crafting tailored campaigns that seamlessly reach and captivate our customers. Their innovative ideas and state-of-the-art techniques have consistently propelled us ahead of the competition.”" },
    { imgURL: require('../Assests/Images/person-3.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-1.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-1.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-1.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-2.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-2.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
    { imgURL: require('../Assests/Images/person-3.png'), author: 'Bryan Smith', title:'CEO of Basecamp Corp', testmony:"“ Curabitur gravida placerat purus nec bibendum. Proin porttitor varius risus blandit dictum. Vestibulum at erat ut velit egestas venenatis sit amet nec metus.”" },
  ]; 
  const getstartedClick = () => {
    window.location.href = "https://master.d21w7m43snw0qn.amplifyapp.com/" 
  };


    const handleClick = () => {
      // Navigate to the new detailed router page
      navigate('/contact'); // Update the path accordingly
    };

  return (
    <><div
     >
      <div className="flex flex-col-reverse lg:flex-row pt-[10rem] pb-10 px-5 md:px-14 bg-no-repeat bg-cover"
       style={{
        backgroundImage: theme === "light" ? `url(${BannerLight})` : `url(${BannerDark})`,
      }}>
        <div className="w-full lg:w-3/5  flex flex-col">
          <h1
            className={`font-semibold text-[33px] lg:text-[50px] xl:text-[72px] leading-extra-loose ${theme === "light" ? "text-black" : "text-white "} `}
          >
            Expert Consulting & innovative solutions
          </h1>
          <p
            className={`font-medium text-sm lg:text-base leading-200 py-5  lg:pr-[4.5rem] ${theme === "light" ? "text-[#666A6F]" : "text-white "} `}
          >
            Gaskon is your trusted Partner for Business Excellence. With a
            proven track record and a team of seasoned consultants spanning
            diverse industries, we deliver tailored solutions dedicated to
            propelling your success. Explore the power of strategic insight,
            data-driven decision-making, and personalized solutions. Your
            journey to business elevation begins here at Gaskon Consulting.
          </p>

          <div onClick={getstartedClick} className="h-12 w-44 rounded-full bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70 mt-4">
            <div className="flex h-full w-full rounded-full items-center justify-center back text-white">
              <h1 className="text-sm font-medium">Get Started</h1>{" "}
              <span>
                <div className="ml-10">
                  <ArrowRightIcon className="h-5 w-5 text-white" />
                </div>
              </span>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:items-center mt-8 lg:mt-20">
            <div
              className={`w-full lg:w-1/4 text-sm font-semibold leading-relaxed ${theme === "light" ? "text-black" : "text-[#FFEBEB]"} `}
            >
              Trusted by the world's  biggest brands
            </div>
            <div
              className={`flex lg:justify-end lg:ml-10 mt-5 lg:mt-0 ${theme === "light" ? "" : "icons"} `}
            >
              <HomeLogos />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/5 flex flex-col pb-5  md:py-8 gap-5">
          <div className="flex w-full gap-5">
          <div className="w-1/2 h-full relative">
  <div className="w-full h-full overflow-hidden">
   <img src={CurveImage} alt="" className="w-full h-[190px] md:h-full object-fill"  />
  </div>

  <div className="absolute top-0 right-0 -mt-10">
    {theme === "light" ? <RoundShapeIcon /> : <RoundShapeDarkIcon />}
  </div>
</div>


            <div className={`w-1/2 ${theme === "light" ? "" : "imageTwo"} `}>
              <div
                className={`w-full h-full flex flex-col rounded-xl  px-5 py-2 ${theme === "light" ? "bg-black" : "bg-[#0076D0]"} `}
              >
                <div className="flex items-center md:pt-8">
                  <div
                    className={`text-xl md:text-[50px] lg:text-[70px] xl:text-[84px]  font-bold ${theme === "light" ? "text-white" : "text-black"} `}
                  >
                    230
                  </div>{" "}
                  <span
                    className={`text-xl md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold  ${theme === "light" ? "text-[#0076D0]" : "text-black"} `}
                  >
                    +
                  </span>
                </div>
                <div className=" md:pt-10 xl:pb-4">
                  <span className="text-[9px] md:text-base text-white md:leading-relaxed">
                    Businesses with whom we collaborate place a high level of
                    trust in our services
                  </span>
                </div>
                <div className="relative h-2 rounded-md w-full bg-white mt-6">
                  <div
                    className={`w-1/2 h-full  rounded-md  ${theme === "light" ? "bg-[#0076D0]" : "bg-black"} `}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <BannerRightImageTwo />
          </div>
        </div>
      </div>

    </div>
    <div className={`flex flex-col py-16 px-5 md:px-14  ${theme === "light" ? "bg-white" : "bg-black"}`}>
        <div className={`flex w-full flex-col  md:flex-row ${theme === "light" ? "" : "text-[#FCFEFF]"} `}>
          <div className="w-full md:w-1/2 lg:pr-10">
            <p className="text-[36px] lg:text-[48px] font-semibold leading-130">Professional service provided by experts with unrivalled expertise</p>
          </div>
          <div className="w-full md:w-1/2 lg:px-10 pt-5">
            <p className={`text-sm lg:text-base leading-180 ${theme === "light" ? "text-[#666A6F]" : "text-[#FCFEFF]"}`}>At Gaskon Consulting, we are dedicated to delivering top-tier business consulting services that empower companies to achieve peak performance. Our team of experienced professionals combines industry knowledge with innovative solutions to help businesses thrive in today’s competitive landscape. Discover how Gaskon Consulting can take your organization to new heights.</p>
          </div>
        </div>

        <div className="py-10 w-full flex flex-col">
        <img className="bg-cover w-full rounded-[2rem] lg:rounded-md" src={imageSrc} alt="" />

        <div className={`flex w-full flex-col py-10 md:flex-row ${theme === "light" ? "" : "text-[#FCFEFF]"} `}>
          <div className="w-full md:w-1/2 pr-10">
            <p className="text-[30px] lg:text-[3.42857rem] font-semibold leading-130">Our Expertise in Business Consulting</p>
          </div>
          <div className="w-full flex flex-col md:w-1/2 lg:px-10 pt-5 space-y-3">
            <div className="flex items-center">
<CheckTickIcon width={"32"} height={"32"} />
<p className={`text-[1.28571rem]  leading-130 pl-2 ${theme === "light" ? "text-[#666A6F]" : "text-[#FCFEFF]"}`}>Dedicated Client-Centric Approach</p>
          
            </div>
            <div className="flex items-center my-3 lg:my-0">
<CheckTickIcon  width={"32"} height={"32"} />
<p className={`text-[1.28571rem]  leading-130 pl-2 ${theme === "light" ? "text-[#666A6F]" : "text-[#FCFEFF]"}`}>Continuous Learning and Innovation</p>
          
            </div>
            <div className="flex items-center">
<CheckTickIcon  width={"32"} height={"32"} />
<p className={`text-[1.28571rem]  leading-130 pl-2 ${theme === "light" ? "text-[#666A6F]" : "text-[#FCFEFF]"}`}>Expertise Across Industries</p>
          
            </div>
          </div>
        </div>

        <p className={`text-sm lg:text-[1.14286rem] leading-180 ${theme === "light" ? "text-[#666A6F]" : "text-[#C3C3C3]"}`}>Gaskon Consulting is a trusted partner for businesses seeking excellence. With a proven track record in the industry, we offer a unique blend of strategic insight, data-driven decision-making, and personalized solutions. Our team comprises seasoned consultants who bring years of experience across various sectors. We pride ourselves on understanding the intricacies of your industry and leveraging that knowledge to drive your success.</p>
        </div>

        <div className="flex flex-col md:flex-row w-full gap-8">
<div className="w-full md:w-2/6">
<CompanyGrowth title={"Projects culminated with outstanding success and excellence."} count={920} imgShow={true} bgImageShow={false} color={"bg-black"} textColor={"text-white"} subTitle={""} isSubTitle={false} bgColor={"bg-[#0076D0]"} />
</div>
<div className="w-full md:w-2/6">
<CompanyGrowth title={"Businesses have contributed to growth and the achievement of their goals."} count={1140} imgShow={true} bgImageShow={false} color={"bg-black"} textColor={"text-white"} subTitle={""} isSubTitle={false} bgColor={"bg-[#0076D0]"} />
</div>
<div className="w-full md:w-2/6">
<CompanyGrowth title={"Revenue Generated with different companies around the world."} count={"$125M"} imgShow={false} bgImageShow={true} color={"bg-black"} textColor={"text-white"} subTitle={""} isSubTitle={false} bgColor={"bg-[#0076D0]"} />
</div>

        </div>
        

      </div>
      <div className="px-5 md:px-14 flex flex-col bg-gradient-to-b from-cyan-50 via-cyan-100 bg-[#fff] ">

<div className={`flex w-full flex-col md:flex-row items-end py-10 ${theme === "light" ? "text-[#010205" : "text-[#010205"} `}>
          <div className="w-full md:w-1/2 md:pr-10 justify-center items-center ">
            <p className="text-[48px] font-semibold leading-130">Thinking forward for your results.</p>
          </div>
          <div className="w-full md:w-1/2 md:px-10 pt-5">
            <p className={`text-base leading-180 ${theme === "light" ? "text-[#666A6F]" : "text-[#878C91]"}`}>In the pursuit of your success, Gaskon Consulting takes a forward-thinking stance, anticipating opportunities and challenges to drive optimal results. Our commitment to innovation, strategic foresight, and proactive solutions positions your business on a trajectory of sustained growth. Experience the impact of thinking forward with Gaskon Consulting as your strategic partner.</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full gap-4 mt-10">
        <div className="w-full md:min-h-[238px] max-h-80  md:w-2/6 shadow-sm rounded-[30px] md:rounded-md">
<CompanyGrowth title={"Strategic Business Planning"} count={false} imgShow={false} bgImageShow={false} color={"bg-white"} textColor={"text-black font-semibold"} subTitle={"Consulting services that empower companies"} isSubTitle={true} bgColor={"bg-white"} />
</div>

<div className="w-full md:min-h-[238px] max-h-80 md:w-2/6 shadow-sm rounded-[30px] md:rounded-md">
<CompanyGrowth title={"Strategic Business Planning"} count={false} imgShow={false} bgImageShow={false} color={"bg-white"} textColor={"text-black font-semibold"} subTitle={"Consulting services that empower companies"} isSubTitle={true} bgColor={"bg-white"} /></div>
<div className="w-full md:min-h-[238px] max-h-80 md:w-2/6 shadow-sm rounded-[30px] md:rounded-md">
<CompanyGrowth title={"Strategic Business Planning"} count={false} imgShow={false} bgImageShow={false} color={"bg-white"} textColor={"text-black font-semibold"} subTitle={"Consulting services that empower companies"} isSubTitle={true} bgColor={"bg-white"} /></div>

        </div>
        <div className="flex flex-col md:flex-row w-full gap-4 mt-6">
        <div className="w-full md:min-h-[238px] max-h-80 md:w-2/6 shadow-sm rounded-[30px] md:rounded-md">
<CompanyGrowth title={"Strategic Business Planning"} count={false} imgShow={false} bgImageShow={false} color={"bg-white"} textColor={"text-black font-semibold"} subTitle={"Consulting services that empower companies"} isSubTitle={true} bgColor={"bg-white"} /></div>

<div className="w-full md:min-h-[238px] max-h-80 md:w-2/6 shadow-sm rounded-[30px] md:rounded-md">
<CompanyGrowth title={"Strategic Business Planning"} count={false} imgShow={false} bgImageShow={false} color={"bg-white"} textColor={"text-black font-semibold"} subTitle={"Consulting services that empower companies"} isSubTitle={true} bgColor={"bg-white"} /></div>
<div className="w-full md:min-h-[238px] max-h-80 md:w-2/6 shadow-sm rounded-[30px] md:rounded-md">
<CompanyGrowth title={"Strategic Business Planning"} count={false} imgShow={false} bgImageShow={false} color={"bg-white"} textColor={"text-black font-semibold"} subTitle={"Consulting services that empower companies"} isSubTitle={true} bgColor={"bg-white"} /></div>

        </div>
        
      </div>
     
      <div>
        <MarketingObjectives/>
      </div>

        <div 
         className={`flex flex-col md:flex-row py-8 px-5 md:px-14  ${theme === "light"
         ? "bg-gradient-to-r from-[#88CAFD] via-[#88CAFD] bg-[#B7FCF0] "
         : "bg-[#0076D0] text-white "} `}>
<div className="w-full md:w-1/2">
<p className="text-4xl md:text-5xl leading-snug pb-10">We're Committed to Providing You with the Best Possible Service</p>

<span className={`text-base  ${theme === 'light' ? "text-[#666A6F]" : "text-[#D9D9D9]"}`}>Contact Gaskon Consulting Today and Let’s Get Started!</span>
<div className="flex pt-8 items-center">
<button  className={` border border-white text-base font-bold rounded-full h-14 px-14 hover:opacity-70 ${theme === "light" ? "bg-white text-black" : "bg-transparent text-white"} `}>More Questions</button>

<span  className={`text-base font-bold ml-6 hover:opacity-70 cursor-pointer ${theme === "light" ? 'text-black' : 'text-white'}`} onClick={handleClick}>Contact Us</span>
</div>
</div>
<div className="w-full md:w-1/2 flex flex-col justify-center md:pr-16 md:pl-10 md:pt-0 pt-12">
<span className={`text-2xl border-b text-center md:text-left md:border-y  pl-6 py-4 md:py-2 font-semibold ${theme === 'light' ? "text-black border-black" : "text-white border-white"}`}>Schedule</span>
<span className={`text-2xl border-b text-center md:text-left  pl-6 py-4 md:py-2 font-semibold ${theme === 'light' ? "text-black border-black" : "text-white border-white" }`}>Diagnostic</span>
<span className={`text-2xl border-b text-center md:text-left pl-6 py-4 md:py-2 font-semibold ${theme === 'light' ? "text-black border-black" : "text-white border-white"}`}>Develop</span>
<span className={`text-2xl md:border-b  text-center md:text-left pl-6 py-4 md:py-2 font-semibold ${theme === 'light' ? "text-black border-black" : "text-white border-white"}`}>Service</span>
</div>
        </div>
        <div className={`flex flex-col py-8 px-4 lg:px-14  ${theme === 'light' ? 'bg-[#FAFAFA] ' : ''}`}>
<div className="flex flex-col md:flex-row md:items-end mb-10 md:mb-16">
  <span className="text-4xl mb-[30px] md:mb-0 md:text-5xl text-black">What people say.</span>
  <span className="text-base text-[#666A6F] pl-2">Don’t Listen To Us, Listen To Our Great Clients!</span>
</div>
        <div className="hidden lg:block">
        <TestMonialGrid testimonials={testimonials} />
        </div>
        <div className="block lg:hidden ">
        <TestMonialSlider testimonials={testimonials} />
    </div>
    </div>
      </>
  );
};

export default Home;
