import React from 'react'
import {EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useTheme } from "../ThemeProvider";
import BannerLight from "../Assests/Images/banner_img.png";
import BannerDark from "../Assests/Images/banner_bg.png";
import ContactMap from "../Assests/Images/contact-map.png"


const Contact = () => {
    const { theme } = useTheme();
  return (
    <div
     >
      <div className="flex flex-col-reverse lg:flex-row lg:justify-between   pt-[10rem] pb-10 px-5 lg:px-14 bg-no-repeat bg-cover"
       style={{
        backgroundImage: theme === "light" ? `url(${BannerLight})` : `url(${BannerDark})`,
      }}>
        
        <div className="w-full lg:w-1/2 lg:mr-[64px] flex flex-col">
              <div className='lg:flex items-start space-x-4 hidden'>

              <div
              className={`text-sm font-medium flex items-start whitespace-nowrap ${
                theme === "light" ? "text-[#9B9B9C]" : "text-[#C3C3C3]"
              }`}
            >
                <span className="mr-2"> <PhoneIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-white"
              }`} /> </span>
              <NavLink to={""}> 817-880-1304 </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium flex items-start ${
                theme === "light" ? "text-[#9B9B9C]" : "text-[#C3C3C3]"
              }`}
            >
                <span className="mr-2"> <EnvelopeIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-white"
              }`} /> </span>
              <NavLink to={""}>gaskon.io </NavLink>{" "}
            </div>

            <div
              className={`text-sm font-medium flex items-start ${
                theme === "light" ? "text-[#9B9B9C]" : "text-[#C3C3C3]"
              }`}
            >
                <span className="mr-2"><MapPinIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-white"
              }`} /></span>
              <NavLink to={""}> 2972 Westheimer Rd. Santa Ana, Illinois 85486  </NavLink>{" "}
            </div>

              </div>
          <h1
            className={`font-semibold text-[33px] lg:text-[50px] xl:text-[72px] leading-extra-loose mt-[30px] lg:mt-12 ${theme === "light" ? "text-black" : "text-white "} `}
          >
           Contact Us
          </h1>
          <p
            className={`font-medium text-sm lg:text-base leading-200 py-[30px] lg:py-12  lg:pr-[4.5rem] ${theme === "light" ? "text-[#878C91]" : "text-[#C3C3C3] "} `}
          >
            This content is password-protected. Please verify with a password to unlock the content.
          </p>
          <form action="" className='flex flex-col space-y-4'>
          <input type="text" placeholder='Name' className={`rounded-[30px] bg-transparent focus:outline-none px-4 py-5 border-2 ${theme === "light" ? "focus:border-black border-[#9B9B9C] text-[#9B9B9C] " : "focus:border-white border-[#C3C3C3] text-[#C3C3C3]"}   `}/>
          <input type="text" placeholder='Email' className={`rounded-[30px] bg-transparent focus:outline-none px-4 py-5 border-2 ${theme === "light" ? "focus:border-black border-[#9B9B9C] text-[#9B9B9C] " : "focus:border-white border-[#C3C3C3] text-[#C3C3C3]"}   `}/>
          <textarea placeholder='Message' name="Message" id="Message" className={`rounded-[30px] bg-transparent appearance-none focus:outline-none px-4 py-5 border-2 resize-none ${theme === "light" ? "focus:border-black border-[#9B9B9C] text-[#9B9B9C] " : "focus:border-white border-[#C3C3C3] text-[#C3C3C3]"}`}></textarea>
          
          <button type='button' className="w-full h-14 rounded-full bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70 mt-4">
            <div className=" flex h-full w-full rounded-full items-center justify-center back text-white">
              <h1 className="text-base font-bold">Contact</h1>
            </div>
          </button>
          </form>
        </div>

        <div className='w-full lg:w-1/2 lg:ml-[64px] lg:flex lg:justify-end'>
         <div className='rounded-[30px]m-0 w-full lg:w-auto'><img className='w-full lg:w-auto lg:h-full object-bottom' src={ContactMap} alt="map" />

         </div>
        </div>
        
        <div className='flex flex-col space-y-6 lg:hidden'>
           <div className=' flex items-start space-x-4'>
           <div
              className={`text-sm font-medium flex items-start whitespace-nowrap ${
                theme === "light" ? "text-[#9B9B9C]" : "text-[#C3C3C3]"
              }`}
            >
                <span className="mr-2"> <PhoneIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-white"
              }`} /> </span>
              <NavLink to={""}> 817-880-1304 </NavLink>{" "}
            </div>
            <div
              className={`text-sm font-medium flex items-start ${
                theme === "light" ? "text-[#9B9B9C]" : "text-[#C3C3C3]"
              }`}
            >
                <span className="mr-2"> <EnvelopeIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-white"
              }`} /> </span>
              <NavLink to={""}>gaskon.io </NavLink>{" "}
            </div>
           </div>
           <div className='flex items-start pb-6'>
            <div
              className={`text-sm font-medium flex items-start ${
                theme === "light" ? "text-[#9B9B9C]" : "text-[#C3C3C3]"
              }`}
            >
                <span className="mr-2"><MapPinIcon className={`w-5 h-5 ${
                theme === "light" ? "text-black" : "text-white"
              }`} /></span>
              <NavLink to={""}> 2972 Westheimer Rd. Santa Ana, Illinois 85486  </NavLink>{" "}
            </div>
            </div>
         </div>
        
      </div>

    </div>
          
  )
}

export default Contact