import React, { InputHTMLAttributes } from "react";
import { useTheme } from "../../ThemeProvider";
import personSrc from "../../Assests/Images/person.png";
import personSrc1 from "../../Assests/Images/person-1.png";
import personSrc2 from "../../Assests/Images/person-2.png";
import personSrc3 from "../../Assests/Images/person-3.png";
import chartBg from "../../Assests/Images/bg_graph.jpeg";

export interface Props<T = unknown>
  extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  count: any;
  imgShow: boolean;
  bgImageShow: boolean;
  color: string;
  textColor: string;
  subTitle: string;
  isSubTitle: boolean;
  bgColor: string;
}
const CompanyGrowth = ({
  title,
  count,
  imgShow,
  bgImageShow,
  color,
  textColor,
  subTitle,
  isSubTitle,
  bgColor
}: Props): JSX.Element => {
  const { theme } = useTheme();

  return (
    <div className="w-full h-full">
      {bgImageShow ? (
        <div
          className="relative bg-cover bg-center w-full h-[300px] lg:h-full rounded-2xl lg:rounded-xl  px-5 py-2"
          style={{ backgroundImage: `url(${chartBg})` }}
        >
          <div 
          className={`absolute inset-0  opacity-50 w-full h-full rounded-2xl lg:rounded-xl ${
            theme === "light" ? `${color}` : `${bgColor}`
          } `}></div>
          <div className="absolute inset-0 flex items-center justify-center w-full h-full">
          <div
          className="w-full h-full flex flex-col rounded-2xl lg:rounded-xl  px-5 py-2"
        >
          <div className="flex items-center">
            <div
              className={`text-[48px] lg:text-6xl  xl:text-[84px]  font-bold ${
                theme === "light" ? "text-white" : "text-black"
              } `}
            >
              {count}
            </div>{" "}
            <span
              className={`text-[50px] lg:text-[60px] xl:text-[70px] font-bold  ${
                theme === "light" ? "text-[#0076D0]" : "text-black"
              } `}
            >
              +
            </span>
          </div>
          <div>
            <span className="text-2xl text-white leading-relaxed">
              {title}
            </span>
          </div>
          <div className=" w-full pt-10 pb-5">
            {imgShow && (
              <div className="flex gap-4 items-center">
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc1}
                  alt="Person 1"
                />
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc2}
                  alt="Person 2"
                />
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc3}
                  alt="Person 3"
                />
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc}
                  alt="Person"
                />
                <span
                  className={`text-[40px] lg:text-[40px]  xl:text-[5.14286rem] font-semibold relative -top-[0px] xl:-top-[10px] ${
                    theme === "light" ? "text-white" : "text-black"
                  } `}
                >
                  +
                </span>
              </div>
            )}
          </div>
        </div>
          </div>
        </div>
      ) : (
        <div
          className={`w-full h-full flex flex-col rounded-2xl lg:rounded-xl  px-5 py-2 ${
            theme === "light"  ? `${color}` : `${bgColor}`
          } `}
        >
          <div className="flex items-center">
          {count ? (
            <><div
                                  className={`text-[48px] lg:text-6xl  xl:text-[84px]  font-bold ${theme === "light" ? "text-white" : "text-black"} `}
                              >
                                  {count}
                              </div><span
                                  className={`text-[50px] lg:text-[60px] xl:text-[70px] font-bold  ${theme === "light" ? "text-[#0076D0]" : "text-black"} `}
                              >
                                      +
                                  </span></>
             ) : (
                <div className="w-4 h-4 rounded-full bg-[#0076D0] mt-5 mb-5 md:mb-10">

                </div>
             )}
          </div>
          <div>
            <span className={`text-2xl leading-relaxed ${textColor}`}>
              {title}
            </span>

            {isSubTitle && (
                <p className={`text-base  leading-relaxed text-[#666A6F] pt-5 md:pt-10`}>
                {subTitle}
              </p>
            )}
          </div>
          <div className=" w-full pt-10 pb-5">
            {imgShow && (
              <div className="flex lg:gap-2 xl:gap-4 items-center">
                <img
                  className=" xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc1}
                  alt="Person 1"
                />
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc2}
                  alt="Person 2"
                />
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc3}
                  alt="Person 3"
                />
                <img
                  className="xl:w-16 xl:h-16 lg:w-12 lg:h-12 w-11 h-11 rounded-full border border-white"
                  src={personSrc}
                  alt="Person"
                />
                <span
                  className={`text-[40px] lg:text-[40px]  xl:text-[4.14286rem] font-semibold relative -top-[0px] xl:-top-[10px]  ${
                    theme === "light" ? "text-white" : "text-black"
                  } `}
                >
                  +
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyGrowth;
