import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Ourteam from './Pages/Ourteam';
import Footer from './Pages/Components/Footer';
import HeaderNav from './Pages/Components/HeaderNav';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import { ThemeProvider } from './ThemeProvider';
import PortifolioDetails from './Pages/PortifolioDetails';
import Contact from './Pages/Contact';
import GetStarted from './Pages/GetStarted';
import AboutUs from './Pages/AboutUs';
import ScrollToTop from './Pages/Components/ScroolTop';

import { Link, animateScroll as scroll } from 'react-scroll';
import SignupLogin from './Pages/signup-login';


const App = () => {
  return (
    <ThemeProvider>
      <div className="App  3xl:container 3xl:mx-auto">
        <Router>
        <ScrollToTop />
          <div  className="fixed 3xl:relative top-0 w-full z-10">
          <HeaderNav />
          </div>
         <div className='relative'>
         <Link
        to="top" // The element you want to scroll to (id of the target element)
        smooth={true} // Enable smooth scrolling
        duration={800} // Scroll duration in milliseconds
      >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Ourteam />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/portfolio-details" element={<PortifolioDetails />} />
            {/* <Route path="/aboutus" element={<AboutUs />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/getstarted" element={<GetStarted />} />

            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/login" element={<SignupLogin />} />
            
          </Routes>
          <Footer />
          </Link>
          </div>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
