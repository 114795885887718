import React,  { useState }  from 'react'
import { useTheme } from "../ThemeProvider";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import BannerLight from "../Assests/Images/banner_img.png";
import BannerDark from "../Assests/Images/banner_bg.png";
import GetStartedImg from "../Assests/Images/getstarted.png";
import LockLight from "../Assests/Images/lock-light.svg";
import LockDark from "../Assests/Images/lock-dark.svg";
import CloseLight from "../Assests/Images/close-light.svg";
import CloseDark from "../Assests/Images/close-dark.svg";
import CheckIcon from "../Assests/Images/check-icon.svg";


const GetStarted = () =>  {
    const { theme } = useTheme();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
      };
    
      const closePopup = () => {
        setPopupOpen(false);
      };
  return (
    <>
    <div className="flex flex-col justify-center lg:flex-row lg:justify-between pt-[9rem] lg:pt-[10rem] pb-10 px-5 lg:px-14 bg-no-repeat bg-cover"
    style={{
     backgroundImage: theme === "light" ? `url(${BannerLight})` : `url(${BannerDark})`,
   }}>
     <div className='h-auto w-full lg:w-1/2 mb-[18px] lg:mb-0  lg:mr-[15px] rounded-[40px]  lg:object-bottom'>
       <img className='w-full h-full object-cover object-bottom rounded-[40px] border-2 border-white' src={GetStartedImg} alt="gestartedimg" />
     </div>
     <div className={` h-full w-full lg:w-1/2 lg:ml-[15px] rounded-[40px] px-5 py-[34px] lg:px-[85px] lg:pt-[72px] lg:pb-[68px] relative ${theme === "light" ? "bg-black": "bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#05A6D2]"}`}>
        <div className='h-auto flex flex-col justify-center items-center'>
            <img className='mb-5 lg:mb-[100px]' src={`${theme === "light" ? LockLight : LockDark }`} alt="lock" />
        <h2 className={`whitespace-wrap lg:whitespace-nowrap text-[28px] leading-[30px] lg:text-5xl lg:leading-[52px] font-semibold pb-5 lg:pb-12 text-center ${theme === "light" ? "text-white" : "text-black"}`}>Gaskon’s Portfolio</h2>
       <p className={`text-sm lg:text-base lg:leading-7 font-medium pb-5 lg:pb-8 text-center ${theme === "light" ? "text-[#D9D9D9]" : "text-white"}`}>This content is password-protected. Please enter your email to unlock the content.</p>
       <form action="" className='flex flex-col space-y-2 lg:space-y-4 w-full'>
          <input type="email" placeholder='Enter your email' className="text-sm lg:text-base rounded-[30px] bg-transparent focus:outline-none px-4 py-5 border-2 focus:border-white border-[#D9D9D9] text-[#D9D9D9] "/>          
          <button  onClick={openPopup} type='button' className={`w-full h-14 rounded-full ${theme === "light" ? "bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] hover:opacity-70": "bg-white hover:opacity-90"}   p-[1px] cursor-pointer  mt-4`}>
            <div className={`flex h-full w-full rounded-full items-center justify-center back ${theme === "light" ? "text-white": "text-[#0076D0]"}`}>
              <span className="text-base font-bold">Unlock</span>
              <span>
                <div className="ml-2">
                  <ArrowRightIcon className={`h-5 w-5 ${theme === "light" ? "text-white": "text-[#0076D0]"}`} />
                </div>
              </span>
            </div>
          </button>
          </form>
        </div>
        <img  className='absolute top-6 right-6' src={CloseDark} alt="close" />
       
     </div>
     {/* popup */}
     {isPopupOpen && (
        <div className={`fixed inset-0 z-20 overflow-y-auto flex items-center justify-center bg-black bg-opacity-30`}>
              <div className={`${theme === "light" ? "bg-white" : "bg-black"} p-6  rounded-[18px] z-20 px-[38px] lg:px-[85px] py-10 lg:py-[72px]  lg:max-w-[628px] lg:max-h-[422px]  lg:rounded-40px flex flex-col justify-center items-center relative mx-3 `}>
                <button onClick={closePopup} className='absolute top-6 right-6'><img src={`${theme === "light" ? CloseLight : CloseDark}`} alt="" /></button>
                <img className='mb-6 lg:mb-[73px]' src={CheckIcon} alt="checkicon" />
                <h3 className={`text-[22px] lg:text-5xl leading-[24px] lg:leading-[52px] font-semibold mb-3 lg:mb-8 text-center ${theme === "light" ? "text-[#010205]" : "text-white"}`}>We have received your request</h3>
                <p className={`text-sm lg:text-base lg:leading-7 font-medium text-center ${theme === "light" ? "text-[#878C91]" : "text-[#D9D9D9]"}`}>Please check your inbox for the updates</p>
              </div>
            
        </div>
      )}
     
   </div>

   </>
  )
}

export default GetStarted