import React, { useState,useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextButton from '../../../Assests/Images/next-button.svg'
import PrevButton from '../../../Assests/Images/prev-button.svg'
interface Testimonial {
    imgURL: string;
    author: string;
    title: string;
    testmony:string;
}

interface TestimonialSliderProps {
  testimonials: Testimonial[];
}

const TestimonialSlider: React.FC<TestimonialSliderProps> = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
 
  const settings = {
    dots: false,
      infinite: true,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    afterChange: (current: number) => setCurrentIndex(current),
  };
  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  

  return (
    <div className="relative">
      <Slider  ref={sliderRef} {...settings}>
        {testimonials.map((testimonial, index) => (
        <div key={index} className="px-4 py-6 shadow-lg rounded-lg">
          <div className='flex flex-col'>
            <div className='flex '>
<div>
    <img src={testimonial.imgURL} alt="profileimg" className='w-[70px] h-[70px] rounded-full' />
</div>
<div className='flex flex-col pl-2'>
<span className='text-[22px] font-semibold text-[#0076D0] capitalize'>{testimonial.author}</span>
<span className='text-base text-[#666A6F]'>{testimonial.title}</span>
</div>
            </div>
            <p className='text-base text-[#666A6F] py-2'>{testimonial.testmony}</p>
          </div>
          
        </div>
      ))}
      </Slider>
       <div className='flex justify-between items-center mt-1'>
       <button type='button' onClick={handlePrevClick}><img src={PrevButton} alt="prev-button" /></button>
       <div className="  text-[#010205] text-base font-semibold">
       <span className='underline'>0{currentIndex + 1}</span> <span className=' opacity-40'>/ 0{testimonials.length}</span>
      </div>
      <button type='button' onClick={handleNextClick} ><img src={NextButton} alt="next-button" /></button>
       </div>
     
    </div>
  );
};

export default TestimonialSlider;
