import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import React from 'react'
import CheckTickIcon from '../Assests/Images/CheckTickIcon'
import { useTheme } from '../ThemeProvider';

import BannerLight from "../Assests/Images/banner_img.png";
import BannerDark from "../Assests/Images/banner_bg.png";
import PortifolioDetailsImage from "../Assests/Images/portifolioDetails.png";
import PortifolioDetailsGraph from "../Assests/Images/portfoliodetails-graph.png";
import PortifolioDetailsProjects from "../Assests/Images/portfoliodetail-project.png";
import PortifolioDetailsProjectsDark from "../Assests/Images/portfoliodetails-projects-dark.png";
import PortifolioDetailsTesting from "../Assests/Images/portfoliodetails-testing.png";
import MarketingObjectives from './Components/MarketingObjectives';

const PortifolioDetails = () => {
    const { theme } = useTheme();
    const data = ["Strategic Roadmap", "Efficiency Gains", "Innovative Solutions","Market Expansion Plan","Resilience Assurance","Reporting"];
  return (
    <div>
        <div className='flex flex-col md:flex-row px-5 md:px-14 pt-[9rem] bg-cover bg-no-repeat'
        style={{
            backgroundImage: theme === "light" ? `url(${BannerLight})` : `url(${BannerDark})`,
          }}>
            <div className='w-full md:w-5/12 flex flex-col'>
            <p className={`text-[30px] lg:text-5xl font-semibold leading-130 ${theme === "light" ? "text-black" : "text-[#FCFEFF]"}`}>Project title with Transformative Business Consulting</p>

            <div className="w-full flex flex-col pt-10 space-y-3">
            <div className="flex items-center">
<CheckTickIcon width={'28'} height={'28'} />
<p className={`text-base font-semibold  leading-130 pl-2 ${theme === "light" ? "text-black" : "text-[#FCFEFF]"}`}>Dedicated Client-Centric Approach</p>
          
            </div>
            <div className="flex items-center my-3 lg:my-0">
<CheckTickIcon width={'28'} height={'28'} />
<p className={`text-base font-semibold  leading-130 pl-2 ${theme === "light" ? "text-black" : "text-[#FCFEFF]"}`}>Continuous Learning and Innovation</p>
          
            </div>
            <div className="flex items-center">
                <CheckTickIcon width={'28'} height={'28'} />

<p className={`text-base font-semibold  leading-130 pl-2 ${theme === "light" ? "text-black" : "text-[#FCFEFF]"}`}>Expertise Across Industries</p>
          
            </div>
           
          </div>
          <div className="h-12 w-[250px]  rounded-full bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4] p-[1px] cursor-pointer hover:opacity-70 mt-10 px-6">
            <div className="flex h-full w-full rounded-full items-center justify-center back text-white ">
              <h1 className="text-sm font-medium">Talk to an expert now</h1>{" "}
              <span>
                <div className="ml-2">
                  <ArrowRightIcon className="h-5 w-5 text-white" />
                </div>
              </span>
            </div>
          </div>

          
            </div>
            <div className='w-full md:w-7/12 flex flex-col mt-5 md:mt-0'>
            <p
            className={`font-medium text-sm lg:text-base leading-180 ${theme === "light" ? "text-[#666A6F]" : "text-white "} `}
          >
               At Gaskon Consulting, we are dedicated to delivering top-tier business consulting services that empower companies to achieve peak performance. Our team of experienced professionals combines industry At Gaskon Consulting, we are dedicated to delivering top-tier business consulting services that empower companies to achieve peak performance. Our team of experienced professionals combines industry knowledge with innovative solutions to help businesses thrive in today’s competitive landscape. Discover how Gaskon Consulting can take your organization to new heights.
               </p>

               <img className='py-16' src={PortifolioDetailsImage} alt="" />
            </div>
        </div>
        
       <div className={`flex flex-col w-full  px-5 lg:px-14 pt-[30px] lg:pt-[70px] pb-[30px] lg:pb-10 ${theme === "light" ? "bg-white": "bg-black"}`}>
         <div className='flex flex-col md:flex-row w-full lg:mb-8'>
         <div className='w-full md:w-1/2 mb-[30px] md:mb-0 md:mr-10'>
           <h2 className={`text-4xl lg:text-5xl font-semibold lg:leading-[60px] ${theme === "light" ? "text-[#010205]": "text-white" }`}>Our approach and Decisions to make the project successful</h2>
         </div>
         <div className='w-full md:w-1/2'>
          <p className={`text-base font-medium leading-7 ${theme === "light" ? "text-[#878C91]" : "text-[#C3C3C3]" }`}>Embark on a journey of success with Gaskon Consulting as we unveil the strategic decisions and meticulous approach that underpin our project success. From inception to completion, our dedicated team navigates challenges with precision, ensuring tailored solutions and innovative decisions that guarantee project triumph. Explore the Gaskon advantage – where every choice is a step toward your project's unparalleled success.</p>
         </div>
         </div>
         <div className='lg:flex w-full hidden'>
          <div className='w-1/2 mr-10 flex flex-col'>
           <div className='flex flex-wrap'>
           {data.map((item, index) => (
             <div key={index} className={`flex justify-center items-center text-base font-semibold w-fit py-8 px-6 rounded-full text-[#010205] whitespace-nowrap mr-4 mb-4 ${theme === "light" ? "bg-[#F7F7F7]": "bg-white" }`}>{item}</div>
             ))}
           </div>
           <div className=''>
            <img src={PortifolioDetailsGraph} alt="graph" />
           </div>
          </div> 
          <div className='w-1/2 flex items-end'>
             <img src={`${theme === "light" ? PortifolioDetailsProjects : PortifolioDetailsProjectsDark }`} alt="projects" />
          </div>
         </div>
       </div>
       

       <div className={`flex flex-col md:flex-row md:items-center px-[30px] md:px-14 py-5 md:py-[60px] bg-cover bg-no-repeat ${theme === "light" ? "" : "bg-[#0076D0]" }`}
        style={{
            backgroundImage: theme === "light" ? `url(${BannerLight})` : ``,
          }}>
            <div className='w-full md:w-1/2 mb-[30px] md:mb-0 md:mr-[64px]'>
             <img src={PortifolioDetailsTesting} alt="testing services" />
            </div>
            <div className='w-full md:w-1/2 md:ml-[64px]'>
             <h2 className={`text-4xl leading-[46px] lg:text-7xl lg:leading-[79px] font-semibold ${theme === "light" ? "text-[#010205]" : "text-white" }`}>Penetration Testing Services</h2>
             <p className={`py-[30px] md:py-12 text-sm md:text-base leading-7 font-medium ${theme === "light" ? "text-[#878C91]" : "text-[#C3C3C3]" }  `}>How vulnerable is your business? Penetration testing services to identify security gaps before hackers do</p>
             <div className={`h-12 w-full md:w-[294px] rounded-full ${theme === "light" ? "bg-gradient-to-r from-[#0076D0] via-[#0076D0] to-[#0EFED4]"  : "bg-white"} p-[1px] cursor-pointer hover:opacity-70 mt-4`}>
             <div className={`flex h-full w-full rounded-full items-center justify-center back  ${theme === "light" ? "text-white": "text-[#0076D0]"}`}>
              <h1 className="text-base font-bold">Talk to an expert now</h1>
              <span>
                <div className="ml-10">
                  <ArrowRightIcon className={`h-5 w-5 ${theme === "light" ? "text-white": "text-[#0076D0]"}`} />
                </div>
              </span>
            </div>
          </div>
            </div>
        </div>

        <div>
        <MarketingObjectives/>
        </div>
       
    </div>
  )
}

export default PortifolioDetails